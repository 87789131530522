import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { JobsService } from '../../../app/service/jobs.service';
import { SmeService } from '../../../app/service/sme.service';
import { S3Helper } from '../../../app/utility/s3-helper';
import { AppLoader } from '../../../components/loader';
import { CLOUDFRONT_URL } from '../../../config/constant';
import INFO_ICON from '../../../assets/icon_images/info icon.svg';
import CALENDER_ICON from '../../../assets/icon_images/Group 3507.svg'
import { Offcanvas } from 'react-bootstrap';
import { Rating } from 'react-simple-star-rating'

export const InterviewEvaluationReport = () => {
    let { id } = useParams<{ id: string }>();
    const [loading, setLoading] = useState(false);
    const [interview, setInterview] = useState<any>({});
    const [skills, setSkills] = useState<any>([]);
    const [audioUrl, setAudioUrl] = useState<any>('');
    const [totalComment, setTotalComment] = useState<any>('');
    const [shortSummary, setShortSummary] = useState<any>('');
    const [detailedSummary, setDetailedSummary] = useState<any>('');
    const [showNotes, setShowNotes] = React.useState(false);


    const history = useHistory();

    useEffect(() => {
        getInterview();
    }, []);

    const getInterview = () => {
        setLoading(true);
        JobsService.getInterviewById(id).then(res => {
            if (res.error) {
                toast.error(res?.error?.message);
                setLoading(false);
            } else {
                setLoading(false);
                const data: any = [];
                const strSkills = res[0]?.job_mandatory_skills.split(',');
                strSkills.forEach((element: any) => {
                    data.push({ skill: element, isEvaluate: 'NO', experience: 0, competency: 0, comment: '' })
                });
                setSkills([...data]);
                // res.forEach((element: any) => {
                //     element.candidate_name = `${element?.candidate_firstname} ${element?.candidate_lastname}`
                //     element.skills = element?.job_mandatory_skills?.split(',');
                // });
                setInterview(res[0])
            }
        })
    }

    const onChangeEvaluate = (e: any, index: number) => {
        const data = skills;
        data[index].isEvaluate = e;
        setSkills([...data])
    }
    const onSeleteExperienceRate = (value: number, index: number) => {
        const data = skills;
        data[index].experience = value;
        setSkills([...data])
    }
    const onSeleteCompetency = (value: number, index: number) => {
        const data = skills;
        data[index].competency = value;
        setSkills([...data])
    }

    const onSubmitReport = (type: string) => {
        const data: any = {};
        data.feedback = JSON.stringify(skills);
        data.interview_uuid = id;
        data.detailed_summary = detailedSummary;
        data.short_summary = shortSummary;
        data.comments = totalComment;
        data.audio_summary_url = audioUrl;
        data.save_status = type === 'save' ? 1 : 0;
        SmeService.postSmeInterviewsEvalution(data).then(res => {
            if (res.error) {
                toast.error(res?.error?.message);
                setLoading(false);
            } else {
                setLoading(false);
                toast.success("Saved Successfully");
                history.push(`/dashboard/interviews/completedlist`);
            }
        })
    }

    const onSkillComments = (e: any, index: number) => {
        const data = skills;
        data[index].comment = e.target.value;
        setSkills([...data])
    }

    const onUploadAudio = async (event: any) => {
        setAudioUrl('');
        setLoading(true);
        if (event.target.files && event.target.files[0]) {
            const data = {
                interview_uuid: id,
                type: "mp3"
            }
            SmeService.audioSummaryUrl(data).then(async res => {
                if (res?.error) {
                    toast.error(res?.error?.message);
                    setLoading(false);
                } else {
                    const result = await S3Helper.uploadFilesToS3BySigned(res.presignedUrl,
                        event.target.files[0],
                        event.target.files[0]?.type
                    );
                    setAudioUrl(`${res.fileUrl}`);
                    setLoading(false);
                    toast.success("Uploaded Successfully");
                }
            })
        }
    };

    const onChangeTotalComment = (e: any) => {
        setTotalComment(e?.target?.value)
    }
    const onChangeShortSummary = (e: any) => {
        setShortSummary(e?.target?.value)
    }

    const onChangeDetailedSummary = (e: any) => {
        setDetailedSummary(e?.target?.value)
    }

    const onChangeReasonForEvalution = (e: any, index: number) => {
        const data = skills;
        data[index].comment = e.target.value;
        setSkills([...data])
    }

    return (
        <div>
            {loading &&
                <AppLoader loading={loading}></AppLoader>
            }
            <div className='evaluation_report'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='d-flex ps-md-4 me-2 me-lg-0'>
                                <div className="ms-4 me-2 ms-lg-0 me-lg-0 mt-lg-0 mb-lg-0  mt-5 mb-3">
                                    <h5 className='top_heading_styles'>Create Evaluation Report</h5>
                                    <p className='top_para_styles'>You are creating a new evaluation report which will be submitted to the organization</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12 col-12 '>
                            <div className='row border_color rounded-3 ms-2 me-2 ms-lg-0 me-lg-0 '>
                                <div className='col-md-12'>
                                    <div className="row bg-white rounded-3 p-4 mx-2 mx-md-0">
                                        <div className='col-12 d-flex justify-content-end'>
                                            <b className='sx-text-primary mb-2 fs_14 me-3 pointer' onClick={() => setShowNotes(true)}>Notes</b>
                                            {/* <b className='sx-text-primary mb-2 fs_14'>Job Description</b> */}
                                        </div>
                                        <div className="col-md-12 pe-md-4">
                                            <div className='row justify-content-between'>
                                                <div className="col-6 mb-3">
                                                     <div>
                                                        <label className="input">
                                                            <input type="text" className="form-control job_dis_form_control px-3 rounded manual_profile_padding input__field" id="interviewID" name="company_name" defaultValue={interview?.id} disabled placeholder="Prefilled ID - Can Edit" />
                                                            <span className="input__label input__label_disabled">Interview ID</span>
                                                        </label>
                                                    </div>

                                                </div>
                                                <div className="col-5 mb-3">
                                                    <label className="input">
                                                        <input type="text" className="form-control job_dis_form_control px-3 rounded manual_profile_padding input__field" id="jobTitle" name="company_name" defaultValue={interview?.job_title} disabled placeholder="Fetched Job Title" />
                                                        <span className="input__label input__label_disabled">Job Title</span>
                                                    </label>
                                                </div>
                                                <div className="col-6 mb-3 ">
                                                    <label className="input">
                                                        {interview?.candidate_firstname && <input type="text" className="form-control job_dis_form_control px-3 rounded manual_profile_padding input__field" id="candidate" name="company_name" defaultValue={`${interview?.candidate_firstname} ${interview?.candidate_lastname} `} disabled placeholder="Fetched Candidate Name" />}
                                                        <span className="input__label input__label_disabled">Candidate</span>
                                                    </label>
                                                </div>
                                                <div className="col-5 mb-3 ">
                                                      <label className="input">
                                                        <input type="text" className="form-control job_dis_form_control px-3 rounded manual_profile_padding input__field" id="dateTime" name="company_name" defaultValue={interview?.interview_schedule} disabled placeholder="Fetched Data and Time" />
                                                        <span className="position-absolute" style={{ left: "92%", bottom: '12px' }}>
                                                            <img src={CALENDER_ICON} alt="" />
                                                        </span>
                                                        <span className="input__label input__label_disabled">Date &amp; Time</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <hr></hr>
                                            {skills?.map((data: any, index: number) => {
                                                return <div key={index}>
                                                    <div className='evaluation_report_left_side_row_two'>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className=''>
                                                                    <label className='para_style'>Did you evaluate the candidate for <span className='fw_6'>{data?.skill}</span> <span style={{ color: 'red', fontSize: '15px' }}>*</span></label>
                                                                </div>
                                                                <div className='d-flex'>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="radio" value={data?.isEvaluate} checked={data?.isEvaluate === 'YES' ? true : false} onChange={(e) => onChangeEvaluate('YES', index)} id="flexRadioDefault1" placeholder=' ' />
                                                                        <label className="form-check-label para_style">
                                                                            Yes
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check mx-3">
                                                                        <input className="form-check-input" type="radio" value={data?.isEvaluate} checked={data?.isEvaluate === 'NO' ? true : false} id="flexRadioDefault2" onChange={(e) => onChangeEvaluate('NO', index)} placeholder=' ' />
                                                                        <label className="form-check-label para_style">
                                                                            No
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                {data?.isEvaluate === 'YES' && <div>
                                                                    <div className='row mt-3'>
                                                                        <div className='col-4'>
                                                                            <span className='fw_6 mr-2'>Experience:</span>
                                                                            <Rating onClick={(val) => onSeleteExperienceRate(val, index)} size={22} transition={true} disableFillHover={true} initialValue={0} />
                                                                        </div>
                                                                        <div className='col-4'>
                                                                            <span className='fw_6 mr-2'>Competency:</span>
                                                                            <Rating onClick={(val) => onSeleteCompetency(val, index)} size={22} transition={true} disableFillHover={true} initialValue={0} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-3 mt-3">
                                                                        <label className="input">
                                                                            <textarea className="form-control job_dis_form_control rounded manual_profile_padding input__field" placeholder=' ' onChange={(e) => onSkillComments(e, index)}></textarea>
                                                                            <span className="input__label">Comments
                                                                                {/* <span style={{ color: 'red', fontSize: '15px' }}>*</span> */}
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </div>}
                                                                {data?.isEvaluate === 'NO' && <div>
                                                                    <div className='mt-2'>
                                                                        <label className="input">
                                                                            <input type="text" className="form-control job_dis_form_control px-3 rounded manual_profile_padding input__field " name="detailed_summary" id="detailed_summary" onChange={(e) => onChangeReasonForEvalution(e, index)} placeholder=" " />
                                                                            <span className="input__label">Reason for not evaluating
                                                                                {/* <span style={{ color: 'red', fontSize: '15px' }}>*</span> */}
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                        <hr className='mt-5' />
                                        <div className="col-md-12 mt-4">
                                            <div className='' style={{ borderBottom: "none" }}>
                                                <div className='col-12'>
                                                    <div className="mb-2">
                                                        <label className="input">
                                                            <textarea className="form-control job_dis_form_control px-3 rounded manual_profile_padding input__field" style={{ boxShadow: "none", height: "99px" }} id="exampleFormControlTextarea1" placeholder='' onChange={(e) => onChangeDetailedSummary(e)}></textarea>
                                                            <span className="input__label">Detailed Summary </span>
                                                        </label>
                                                    </div>
                                                    <div className="mb-3 mt-3">
                                                        <label className="input">
                                                            <textarea className="form-control job_dis_form_control px-3 rounded manual_profile_padding input__field" style={{ boxShadow: "none", height: "99px" }} id="exampleFormControlTextarea1" placeholder='' onChange={(e) => onChangeShortSummary(e)}></textarea>
                                                            <span className="input__label">Short Summary</span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label className="input">
                                                            <textarea className="form-control job_dis_form_control px-3 rounded manual_profile_padding input__field" style={{ boxShadow: "none", height: "99px" }} id="exampleFormControlTextarea1" placeholder='' onChange={(e) => onChangeTotalComment(e)}></textarea>
                                                            <span className="input__label">Your comments on the screening instructions</span>
                                                        </label>
                                                    </div>
                                                    <div className='mt-4'>
                                                        <div className="mb-4">
                                                            <div className="file px-3  rounded-5  d-inline-block large_btn_apply text-dark">Upload Audio
                                                                <span className='ms-2'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                                                                        <g id="_1" data-name="1" transform="translate(-54.13 -50.139)">
                                                                            <path id="Path_1605" data-name="Path 1605" d="M109.3,64.414H103.45a.634.634,0,0,1-.634-.634V57.99H100.44a.634.634,0,0,1-.471-1.059l5.934-6.582a.634.634,0,0,1,.943,0l5.933,6.582a.634.634,0,0,1-.471,1.059h-2.375v5.789A.634.634,0,0,1,109.3,64.414Zm-5.214-1.269h4.58V57.356a.634.634,0,0,1,.634-.634h1.583l-4.508-5-4.508,5h1.585a.634.634,0,0,1,.634.634Z" transform="translate(-43.744)" />
                                                                            <path id="Path_1606" data-name="Path 1606" d="M68.369,338.69H56.891a2.764,2.764,0,0,1-2.761-2.761v-1.614a.634.634,0,0,1,1.269,0v1.614a1.494,1.494,0,0,0,1.493,1.493H68.369a1.494,1.494,0,0,0,1.492-1.493v-1.614a.634.634,0,0,1,1.269,0v1.614A2.764,2.764,0,0,1,68.369,338.69Z" transform="translate(0 -271.55)" />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                                <input type="file" accept="audio/mp3" onChange={(e) => onUploadAudio(e)} />
                                                            </div>
                                                            <span>
                                                                <img src={INFO_ICON} alt="info icon" className="ms-3 mb-4" />
                                                            </span>
                                                            {audioUrl && <audio src={`${CLOUDFRONT_URL}/${audioUrl}`} controls ></audio>}
                                                            {audioUrl && <i className="bi bi-trash-fill btn btn-danger cursor-pointer upload_delete" onClick={() => setAudioUrl('')} ></i>}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 d-flex justify-content-between mt-4'>
                                            <button className='large_btn_apply btn-outline-primary rounded-3 ' onClick={() => onSubmitReport('draft')}>Save as draft</button>
                                            <button className='large_btn_apply rounded-3' onClick={() => onSubmitReport('save')}>Submit Report</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Popup when user click notes from top right corner of the form */}
            <Offcanvas show={showNotes} onHide={() => setShowNotes(false)} placement={'end'}>
                <Offcanvas.Body>

                    <div className="bg-white p-4">

                        <div className="d-flex justify-content-between">
                            <div>
                                <h5 className="download_heading">
                                    {interview?.candidate_firstname}{" "}
                                    {interview?.candidate_lastname}
                                </h5>
                            </div>
                            <button
                                className="dashboard_happy_monday_dot_btn px-2 py-1 rounded mx-2"
                                onClick={() => setShowNotes(false)}
                            >
                                <svg
                                    width="12"
                                    height="12"
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M11.8327 1.34167L10.6577 0.166668L5.99935 4.825L1.34102 0.166668L0.166016 1.34167L4.82435 6L0.166016 10.6583L1.34102 11.8333L5.99935 7.175L10.6577 11.8333L11.8327 10.6583L7.17435 6L11.8327 1.34167Z"
                                        fill="black"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div>
                            <p className='top_para_styles'>{interview?.notes}</p>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas >
        </div>


    )
}
