import { CLOUDFRONT_URL } from 'config/constant';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import PROFILE_ICON from '../../assets/images/profile.png';
import STAR_ICON from '../../assets/icon_images/star.svg';
import STAR_ICON_WITH_YELLOW from '../../assets/icon_images/star_yellow.svg';
import { DataTable } from '../../components/data-table';
import { SkillDataGridCols } from 'pages/dashboard/sme/profile/data-grid-cols';
import { SmeService } from '../../app/service/sme.service';
import OpenCV from '../../assets/icon_images/Open CV.png';
import { SMEOverviewSkills } from './sme-overview-skills';

export const SMEOverviewPopup = (props: any) => {
  const [showPopup, setShowPopup] = useState(props.isShowPopup);
  const [matchingSkills, setMatchingSkills] = useState<any>([]);
  const [extraSkills, setExtraSkills] = useState([]);
  const [smeProfileData, setSmeProfileData] = useState<any>({});
  useEffect(() => {
    setShowPopup(props.isShowPopup);
    if (props?.smeData?.matchedSkills) {
      setMatchingSkills(
        props.smeData.matchedSkills.map((skill: any) => {
          return {
            experienceDisplay: Number(skill?.experience) + ' yrs',
            proficiency: skill.proficiency,
            skill: skill?.skill,
          };
        })
      );
      setSmeProfileData({}); //This will clears previously selected smes data
      // ToDo - Add a loader functionality inside final block
      SmeService.getSmeProfileById(props?.smeData?.uuid).then((data) => {
        setSmeProfileData(data);
      });

      SmeService.getSmeSkillsById(props?.smeData?.uuid).then((data) => {
        setExtraSkills(
          data
            .map((skill: any) => {
              if (
                props.smeData.matchedSkills.findIndex(
                  (matched_skill: any) => matched_skill.skill == skill.skill
                ) == -1
              )
                return {
                  experienceDisplay: Number(skill?.experience) + ' yrs',
                  proficiency: skill?.proficiency,
                  skill: skill?.skill,
                };
            })
            .filter((skill: any) => skill != undefined)
        );
      });
    }
  }, [props.isShowPopup]);
  return (
    <>
      <Modal
        show={showPopup}
        onHide={() => setShowPopup(false)}
        aria-labelledby='contained-modal-title-vcenter'
        className='sx-close w-100 content-size-xl'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className='w-100 row'>
          <div className='col-3 pl-2 '>
            <img
              className='fs_14 mt-2 mb-3'
              style={{
                height: '150px',
                width: '150px',
                borderRadius: '50%',
              }}
              src={
                props.smeData.user_image && props.smeData.user_image.indexOf('https') > -1
                  ? props.smeData.user_image
                  : props.smeData.user_image
                  ? `${CLOUDFRONT_URL}/${props.smeData.user_image}`
                  : PROFILE_ICON
              }
              alt='SME Profile Picture'
            />
            {/* <div className="mt-3 mb-3" style={{ fontSize: "15px", paddingLeft: "8px" }}>
                            4.3
                            <span>
                                <img src={STAR_ICON_WITH_YELLOW} alt="" />
                            </span>
                            <span>
                                <img src={STAR_ICON_WITH_YELLOW} alt="" />
                            </span>
                            <span>
                                <img src={STAR_ICON_WITH_YELLOW} alt="" />
                            </span>
                            <span>
                                <img src={STAR_ICON_WITH_YELLOW} alt="" />
                            </span>
                            <span>
                                <img src={STAR_ICON} alt="" />
                            </span>
                        </div> */}
            {smeProfileData && (
              <div>
                {/* {smeProfileData?.resume_url && (
                  <a
                    className='btn-signup mt-3 mb-3'
                    target='_blank'
                    href={`${CLOUDFRONT_URL}/${smeProfileData?.resume_url}`}
                  >
                    Open CV{' '}
                    <img src={OpenCV} className='ps-2 pb-1' alt='opencv' />{' '}
                  </a>
                )} */}
                <div className='col-12 padding_rm mt-3'>
                  <div className='first_name_container'>
                    <span className='first_name_title'>Full Name</span>
                    <p className='first_name_value'>
                      {props.smeData.user_firstname} {props.smeData.user_lastname}
                    </p>
                  </div>
                </div>
                <div className='col-12 padding_rm'>
                  <div className='first_name_container'>
                    <span className='first_name_title'>Current Title</span>
                    <p className='first_name_value'>{smeProfileData?.expert_title}</p>
                  </div>
                </div>
                <div className='col-12 padding_rm'>
                  <div className='first_name_container'>
                    <span className='first_name_title'>Rate per Interview</span>
                    <p className='first_name_value'>${smeProfileData?.sme_fee}</p>
                  </div>
                </div>
                <div className='col-12 padding_rm'>
                  <div className='first_name_container'>
                    <span className='first_name_title'>No of Interviews Conducted</span>
                    <p className='first_name_value'>{smeProfileData?.no_of_completed_interviews}</p>
                  </div>
                </div>
                <div className='col-12 padding_rm file'>
                  <div className='first_name_container'>
                    <span className='first_name_title'>LinkedIn URL</span>
                    <p className='first_name_value'>{smeProfileData?.linkedin_url}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='col-3 border-left-grey'>
            <div className='side_heading_sme mb-3'>Expert Skills</div>
            {matchingSkills.filter((skill: any) => skill.proficiency == 'Expert').length !== 0 && (
              <SMEOverviewSkills
                tableData={matchingSkills.filter((skill: any) => skill.proficiency == 'Expert')}
                extraSkills={extraSkills.filter((skill: any) => skill.proficiency == 'Expert')}
              />
            )}
          </div>
          <div className='col-3 border-left-grey'>
            <div className='side_heading_sme mb-3'>Advanced Skills</div>
            {matchingSkills.filter((skill: any) => skill.proficiency == 'Advanced').length !==
              0 && (
              <SMEOverviewSkills
                tableData={matchingSkills.filter((skill: any) => skill.proficiency == 'Advanced')}
                extraSkills={extraSkills.filter((skill: any) => skill.proficiency == 'Advanced')}
              />
            )}
          </div>
          <div className='col-3 border-left-grey'>
            <div className='side_heading_sme mb-3'>Basic Skills</div>
            {matchingSkills.filter((skill: any) => skill.proficiency == 'Basic').length !== 0 && (
              <SMEOverviewSkills
                tableData={matchingSkills.filter((skill: any) => skill.proficiency == 'Basic')}
                extraSkills={extraSkills.filter((skill: any) => skill.proficiency == 'Basic')}
              />
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
