export enum SX_ROLES {
    SME = 'SME',
    SuperAdmin = 'SuperAdmin',
    CompanyAdmin = 'CompanyAdmin',
    Recruiter = 'Recruiter',
    HR_Admin = 'HR_Admin',
    Candidate = 'Candidate'
}

export enum ERROR_CODES {
    COMPANY_ALREADY_EXIST = 1110,
    USER_ALREADY_EXIST,
    USER_NOT_FOUND,
    USER_NOT_VERIFIED,
    RECORD_NOT_FOUND,
    MISSING_FIELDS,
    INVALID_CREDENTIALS,
    COMPANY_NOT_FOUND,
    INVALID_REQUEST_BODY,
    FAILED_TO_BYCRYPT,
    UN_AUTHORIZED,
    NOT_ALLOWED,
    PROFILE_NOT_FOUND,
    RECORD_ALREADY_EXISTS,
    EMAIL_ERROR
}

export enum JD_ACCESS_TYPES {
    TEAM_MEMBER = 1,
    HIRING_MANAGER,
}

export enum SX_DAY {
    NONE,
    SUNDAY,
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
}

export enum FORGOTPASSWORD_STATUS {
    NONE,
    ACTIVE,
    UPDATED,
    EXPIRED,
    INACTIVE
}

export enum INTERVIEW_STATUS {
    EMPTY = '',
    INTERVIEW_NOT_SCHEDULED = 'INTERVIEW_NOT_SCHEDULED',
    NONE = 'WAITING_FOR_SME_ACCEPT',
    INTERVIEW_SCHEDULED = 'INTERVIEW_SCHEDULED',
    INTERVIEW_TAKEN = 'INTERVIEW_TAKEN',
    COMPLETED = 'COMPLETED',
    CANCELLED_BY_SME = 'CANCELLED_BY_SME',  // accepted but in the middle of the intervew sme cancelled with some emergency
    CANDIDATE_NOT_ATTENDED = 'CANDIDATE_NOT_ATTENDED',
    REJECTED_BY_SME = 'REJECTED_BY_SME'
}

export enum FAVOURITE_SME_TYPES {
    NONE,
    COMPANY,
    USER
}

export enum SX_NOTIFICATION_TYPE {
    NONE,
    JOB_ASSIGNED,
    ACCOUNT_INTIVATION_ACCEPTED,
    CANDIDATES_ADDED,
    INTERVIEWS_SCHEDULED,
    SME_INTERVIEWS_OPPORTUNITY,
    INTERVIEWS_RESCHEDULED,
    SME_INTERVIEWS_RESCHEDULED,
    SME_INTERVIEW_ACCEPTED,
    SME_INTERVIEW_REJECTED,
    SME_INTERVIEW_REMINDER,
    SME_INTERVIEW_COMPLETED,
    SME_INTERVIEW_FEEDBACK_COMPLETED,
    SME_PAYOUT_PROCCESSED,
    SX_NUDGE_MESSAGE
}

export enum SX_NOTIFICATION_SETTING_TYPE {
    NONE,
    EMAIL,
    MOBILE
}

export enum OTP_STATUS {
    NONE,
    ACTIVE,
    UPDATED,
    EXPIRED,
    INACTIVE
}

export enum ACCOUNT_STATUS {
    NONE,
    ACTIVE,
    UNDER_VERIFICATION,
    DISABLED
}