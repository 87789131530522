import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { JobsService } from "../../../app/service/jobs.service";
import { SmeService } from "../../../app/service/sme.service";
import { AppLoader } from "../../../components/loader";
import { CLOUDFRONT_URL } from "../../../config/constant";
import jsPDF from "jspdf";
import { Rating } from "react-simple-star-rating";
import OpenCV from "../../../assets/icon_images/Open CV.png";
import ReactToPrint from "react-to-print";

export const InterviewEvaluationReportView = () => {
  let { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [interview, setInterview] = useState<any>({});
  const [selectedCandidate, setSelectedCandidate] = useState<any>({});
  const [selectedCandidateReport, setSelectedCandidateReport] = useState<any>(
    {}
  );
  const [interviewsZoomInfo, setInterviewsZoomInfo] = useState<any>();
  const history = useHistory();
  const printRef = useRef<any>();

  useEffect(() => {
    getInterviews();
  }, []);

  const getInterviews = () => {
    setLoading(true);
    JobsService.getInterviewById(id).then((res) => {
      if (res.error) {
        toast.error(res?.error?.message);
        setLoading(false);
      } else {
        setLoading(false);
        res[0].candidateFullName = `${res[0]?.candidate_firstname} ${res[0]?.candidate_lastname}`;
        res[0].smeFullName = `${res[0]?.sme_firstname} ${res[0]?.sme_lastname}`;
        setInterview(res[0]);
        setSelectedCandidate(res[0]);
      }
    });

    setSelectedCandidateReport({});
    SmeService.interviewFeedBackByInterview(id).then((res) => {
      if (res.error) {
        toast.error(res?.error?.message);
        setLoading(false);
      } else {
        setLoading(false);
        if (res.length > 0) {
          const reportData: any = res[0];
          reportData.skillsRating = JSON.parse(reportData?.feedback);
          setSelectedCandidateReport(reportData);
        }
      }
    });
    SmeService.interviewZoomInfo(id).then((res) => {
      if (res.error) {
        toast.error(res?.error?.message);
        setLoading(false);
      } else {
        setInterviewsZoomInfo({ ...res });
      }
    });
  };

  const back = (): void => {
    history.push("/dashboard/interviews/completedlist");
  };

  // This code can be removed if jsPDF is really not required
  const downloadFullreport = () => {
    var doc = new jsPDF({
      orientation: "landscape",
      unit: "px",
    });
    var content = document.getElementById("pdf-content");
    if (content) {
      try {
        doc.html(content, {
          margin: [0, 0, 0, 50],
          callback: function (generatedDoc) {
            // generatedDoc.internal.pageSize.height = content?.clientHeight ?? 2000;
            generatedDoc.internal.pageSize.width = content?.clientWidth ?? 1000;
            // generatedDoc.addPage()
            // generatedDoc.save();
          },
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div>
      {loading && <AppLoader loading={loading}></AppLoader>}
      <div className="p-4 pb-2">
        <div className="d-flex justify-content-between ms-2">
          <div>
            <h5 className="download_heading">
              {selectedCandidate?.candidateFullName} - by{" "}
              {selectedCandidate?.smeFullName}
            </h5>
            <p className="download_para">
              Report by SME based on the interview
            </p>
          </div>
        </div>
      </div>
      <div className="ps-3 pe-3 pe-lg-5">
        <div className="row rounded-3 bg-white">
          <div className="px-4 pb-4">
            {/* <p className='sx-text-primary text-end mt-2 fs_14 pointer' onClick={() => downloadFullreport()}><i className="bi bi-download me-2" style={{
                            fontSize: "18px"
                        }}></i>Download Full Report</p> */}

            <ReactToPrint
              trigger={() => (
                <span className="sx-text-primary text-end mt-2 fs_14 pointer dashboard_box_ionc">
                  <i
                    className="bi bi-download me-2"
                    style={{
                      fontSize: "18px",
                    }}
                  ></i>
                  Download Full Report
                </span>
              )}
              content={() => {
                document.title =
                  selectedCandidate?.candidateFullName +
                  " - " +
                  selectedCandidate?.job_title;
                return printRef.current;
              }}
              onAfterPrint={() => {
                document.title = "SiftedX";
              }}
            />

            <div className="row mt-5" id="pdf-content" ref={printRef}>
              <div className="col-md-5 border-end border-end-sm-none pe-md-5 mt-2">
                <div className="d-flex">
                  <p className="download_heading pe-3">Job </p>
                  <p className="top_para_styles">
                    {" "}
                    : {selectedCandidate?.job_title}
                  </p>
                </div>
                <div className="d-flex-print hide-in-screen">
                  <p className="download_heading pe-3">Candidate</p>
                  <p className="top_para_styles fs_14">
                    {" "}
                    : {selectedCandidate?.candidateFullName}
                  </p>
                </div>
                <div className="d-flex ">
                  <p className="download_heading pe-3">Interviewer</p>
                  <p className="sx-text-primary fs_14">
                    {" "}
                    : {selectedCandidate?.smeFullName}
                  </p>
                </div>
                <div className="d-flex ">
                  <p className="download_heading pe-3">
                    Interview date and time
                  </p>
                  <p className="top_para_styles">
                    {" "}
                    :{" "}
                    {moment(selectedCandidate?.interview_schedule).format(
                      "DD MMM YYYY HH:MM"
                    )}
                  </p>
                </div>

                <hr />

                {selectedCandidateReport?.skillsRating?.map(
                  (data: any, index: number) => {
                    return (
                      <div>
                        {data?.isEvaluate == "YES" && (
                          <div className="mb-0">
                            Evaluation for
                            <span className="download_heading ml-1">
                              {data?.skill}
                            </span>
                            <div className="mt-2">
                              <span className="download_heading">
                                {" "}
                                Experience:
                              </span>
                              <Rating
                                readonly={true}
                                size={22}
                                initialValue={data?.experience}
                              />
                            </div>
                            <div className="mt-2">
                              <span className="download_heading">
                                Competency:
                              </span>
                              <Rating
                                readonly={true}
                                size={22}
                                initialValue={data?.competency}
                              />
                            </div>
                            <div className="mt-2">{data?.comment}</div>
                          </div>
                        )}
                        {data?.isEvaluate == "NO" && (
                          <div>
                            Candidate not evaluated for
                            <span className="download_heading ml-1">
                              {data?.skill}
                            </span>
                            <div className="mt-2">
                              {" "}
                              <span className="download_heading">
                                {" "}
                                Reason -{" "}
                              </span>{" "}
                              {data?.comment}{" "}
                            </div>
                          </div>
                        )}
                        <hr />
                      </div>
                    );
                  }
                )}

                <p className="download_heading pe-3 pt-3">Audio summary </p>
                <p className="top_para_styles">
                  {selectedCandidateReport?.audio_summary_url ? (
                    <div>
                      <p className="noprint">
                        <audio
                          src={`${CLOUDFRONT_URL}/${selectedCandidateReport?.audio_summary_url}`}
                          controls
                        />
                      </p>
                      <div className="break-text-next-line hide-in-screen show-on-print">
                        <a
                          href={`${CLOUDFRONT_URL}/${selectedCandidateReport?.audio_summary_url}`}
                        >
                          {`${CLOUDFRONT_URL}/${selectedCandidateReport?.audio_summary_url}`}
                        </a>
                      </div>
                    </div>
                  ) : (
                    <p>No audio summary</p>
                  )}
                </p>

                <hr />

                <p className="download_heading pe-3 pt-3">Video summary </p>
                <p className="top_para_styles">
                  {interviewsZoomInfo?.recordingData[0]?.play_url ? (
                    <div>
                      <p className="noprint">
                        <a
                          className="open_cv"
                          href={interviewsZoomInfo?.recordingData[0]?.play_url}
                          target="_blank"
                        >
                          {" "}
                          Video link
                          <img
                            src={OpenCV}
                            className="ps-4 pb-1 "
                            alt="open video icon"
                          />
                        </a>
                        <div className="mt-3">
                          <span className="download_heading">Passcode: </span>
                          {interviewsZoomInfo?.passcode}
                        </div>
                      </p>
                      <div className="break-text-next-line hide-in-screen show-on-print">
                        <a
                          href={`${interviewsZoomInfo?.recordingData[0]?.play_url}`}
                        >
                          {`${interviewsZoomInfo?.recordingData[0]?.play_url}`}
                        </a>
                        <div className="mt-3">
                          <span className="download_heading">Passcode: </span>
                          {interviewsZoomInfo?.passcode}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <p>No Meeting video found</p>
                  )}
                </p>
                <button
                  className="large_btn_apply rounded-3 btn-outline-primary me-3 mt-3 mb-1 noprint"
                  onClick={() =>
                    history.push("/dashboard/interviews/completedlist")
                  }
                >
                  Back
                </button>
              </div>

              {/* Right side content */}
              <div className="col-md-7 mt-4 mt-md-2 ps-md-3">
                <div>
                  <p className="download_heading">Short Summary</p>
                  <p className="top_para_styles">
                    {selectedCandidateReport?.short_summary ? (
                      <p>{selectedCandidateReport?.short_summary}</p>
                    ) : (
                      <p>No short summary</p>
                    )}
                  </p>
                </div>
                <div>
                  <p className="download_heading">Detailed Summary</p>
                  <p className="top_para_styles">
                    {selectedCandidateReport?.detailed_summary ? (
                      <p>{selectedCandidateReport?.detailed_summary}</p>
                    ) : (
                      <p>No detailed summary</p>
                    )}
                  </p>
                </div>

                <div>
                  <p className="download_heading">
                    Response to Screening Instructions on the Job Description
                  </p>
                  <p className="top_para_styles">
                    {selectedCandidateReport?.comments ? (
                      <p> {selectedCandidateReport?.comments} </p>
                    ) : (
                      <p>No detailed summary</p>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
