import { Step, StepButton, Stepper } from "@mui/material"
import { JobsService } from "app/service/jobs.service"
import { LookUpService } from "app/service/lookup.service"
import { Candidates } from "components/recruiter/candidates"
import { JobDetails } from "components/recruiter/job-details"
import Skills from "components/recruiter/skills"
import { Smes } from "components/recruiter/smes"
import React, { useEffect } from "react"
import { useState } from "react"
import { Modal } from "react-bootstrap"
import { useLocation, useParams } from "react-router-dom"
import { toast } from "react-toastify"

export const CreateJobModal: React.FC<any> = (props: any) => {
    let {id} = useParams<{ id: string; jobId: string }>();
    const [showJobEditPopup, setShowJobEditPopup] = useState(true)
    const steps = ["Job Details", "Skills", "Candidates", "SMEs"];
    const [completed, setCompleted] = React.useState<{
        [k: number]: boolean;
    }>({});
    const [activesteps, setActiveSteps] = React.useState(props.activesteps);
    const [loading, setLoading] = useState(false);
    const locationPath = useLocation().pathname;
    const [selectedjobId, setSelectedjobId] = useState<any>(locationPath.split("/")[7]);
    const [sxSkills, setSxSkills] = useState<any>([]);
    const [experienceList, setExperienceList] = useState<any[]>([]);
    const handleStep = (step: number) => () => {
        setActiveSteps(step);
    };
    const createJob = (jobsData: any): void => {
        setLoading(true);
        jobsData.company_uuid = id;
        JobsService.updateJob(jobsData.uuid, jobsData).then((res) => {
            if (res.error) {
                toast.error(res?.error?.message);
                setLoading(false);
            } else {
                toast.success("Saved Successfully");
                setActiveSteps(1);
                setLoading(false);

            }
        });
    }
    useEffect(() => {

        getAllSkills();
    }, []);

    const getAllSkills = async () => {
        const result = await LookUpService.getAllSkills();
        setSxSkills(result);
    };

    useEffect(() => {
        const experience = [];
        for (let index = 1; index <= 30; index++) {
            experience.push(index);
            setExperienceList([...experience]);
        }
    }, []);


    const saveJobSkills = (data: any) => {
        setLoading(true);
        const jobSkills: any = {}
        const mandatorySkills: any = [];
        const experienceMandatory: any = [];
        const proficiencyMandatory: any = [];
        const optionalSkills: any = [];
        const experienceOptional: any = [];
        const proficiencyOptional: any = [];

        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            if (element.proficiency === "Mandatory") {
                mandatorySkills.push(element.skill)
                experienceMandatory.push(element.experience)
                proficiencyMandatory.push(element.proficiency)
            }
            else if (element.proficiency === "Optional") {
                optionalSkills.push(element.skill)
                experienceOptional.push(element.experience)
                proficiencyOptional.push(element.proficiency)
            }
            jobSkills.job_mandatory_skills = mandatorySkills.toString();
            jobSkills.job_mandatory_skills_exp = experienceMandatory.toString();
            jobSkills.job_mandatory_skills_proficiency = proficiencyMandatory.toString();
            jobSkills.job_optional_skills = optionalSkills.toString();
            jobSkills.job_optional_skills_exp = experienceOptional.toString();
            jobSkills.job_optional_skills_proficiency = proficiencyOptional.toString();

        }
        JobsService.updateJob(selectedjobId, jobSkills)
            .then((res) => {
                if (res.error) {
                    toast.error(res?.error?.message);
                    setLoading(false);
                }
                else {
                    toast.success("Saved successfully");
                    setActiveSteps(2);
                    setLoading(false);
                }
            })
    };
    return (<Modal
        show={true}
        onHide={() => {props.onCloseModal();}}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="content-size-xl sx-close"
    >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
            <div
                className="my-2 px-lg-5 pb-3 px-3"
                style={{ height: "80vh", overflow: "hidden", position: "relative" }}
            >
                <Stepper
                    nonLinear
                    alternativeLabel
                    activeStep={activesteps}
                    className={`w-50 w-sm-100 m-auto mt-3`}
                >
                    {steps.map((label, index) => (
                        <Step key={label} completed={completed[index]}>
                            <StepButton color="inherit" onClick={handleStep(index)}>
                                {label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>

                <>
                    <div
                        className="mb-3 mt-5 mt-lg-0 pe-2"
                        style={{ height: "calc(100% - 140px)", overflow: "auto" }}
                    >
                        {activesteps === 0 && <JobDetails onSave={createJob} jobId={selectedjobId} />}
                        {activesteps === 1 && (
                            <Skills
                                onClose={() => setActiveSteps(0)}
                                basicSkills={[]}
                                advancedSkills={[]}
                                expertSkills={[]}
                                onSave={saveJobSkills}
                                experienceList={experienceList}
                                allSkills={sxSkills}
                                jobId={selectedjobId}
                            />
                        )}
                        {activesteps === 2 && (
                            <Candidates
                                onClose={() => setActiveSteps(1)}
                                saveCandidate={() => setActiveSteps(3)}
                                jobId={selectedjobId}
                                isbutton={false}
                                isFlatList={false}

                            />
                        )}
                        {activesteps === 3 && <Smes jobId={selectedjobId} onClose={() => setActiveSteps(2)}
                            // onSave={submitSme}
                            isbutton={false}
                        // smeError={smeError}
                        />}
                    </div>
                </>
            </div>
        </Modal.Body>
    </Modal>
    )
}