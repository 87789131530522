import { useEffect, useState } from "react";

export const SMEOverviewSkills = (props: any) => {
    const [matchingSkills, setMatchingSkills] = useState<any>(props.tableData);
    const [extraSkills, setExtraSkills] = useState([]);

    useEffect(() => {
        setExtraSkills(props.extraSkills)
    }, [props.extraSkills])

    return (
        <div style={{
            "padding": "10px",
        }}>
            {matchingSkills.length > 0 && <><span style={{
                "borderTopRightRadius": "5px",
                "borderTopLeftRadius": "5px"
            }} className="bg_yellow p-5px">
                Matching the Job
            </span><table style={{
                "border": "solid #ecb133 3px",
                "borderRadius": "5px",
            }} className="table table-borderless table-striped table-sm fs_14 mt-3px mb-4">
                    <thead className="thead-light">
                        <tr>
                            <th
                                style={{
                                    borderTopLeftRadius: "0.3rem",
                                    borderTopRightRadius: "0.3rem"
                                }}
                            >
                                Skills
                            </th>
                            <th>
                                Experience
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {matchingSkills?.map((item: any, i: number) => (
                            <tr
                                key={i}
                            >
                                <td>{item.skill}</td>
                                <td>{item.experienceDisplay}</td>
                            </tr>
                        ))}
                    </tbody>
                </table></>}


            {extraSkills.length > 0 && <> <span style={{
                "borderTopRightRadius": "5px",
                "borderTopLeftRadius": "5px",
                "background": "#e6e8ee"
            }} className="p-5px">
                Other
            </span>
                <table style={{
                    "border": "solid #e6e8ee 3px",
                    "borderRadius": "5px",
                }} className="table table-borderless table-striped table-sm fs_14 mt-3px">
                    <thead className="thead-light">
                        <tr>
                            <th
                                style={{
                                    borderTopLeftRadius: "0.3rem",
                                    borderTopRightRadius:
                                        "0.3rem"
                                }}
                            >
                                Skills
                            </th>
                            <th>
                                Experience
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {extraSkills?.map((item: any, i: number) => (
                            <tr
                                key={i}
                            >
                                <td>{item.skill}</td>
                                <td>{item.experienceDisplay}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>}
        </div >
    )
}