import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import FormBuilder, { FormBuilderData } from '../../components/form-builder';
import ChipInput from '../chip-input';
import INFO_ICON from '../../assets/icon_images/info icon.svg';
import moment from 'moment';
import Select from 'react-select';
import { JobsService } from '../../app/service/jobs.service';
import { toast } from 'react-toastify';
import { UsersService } from '../../app/service/users.service';
import { AppLoader } from '../loader';
import { labels } from '../../app/utility/constants';

interface Props {
  onSave: (data: any) => void;
  jobId: string;
  getChipsFieldData?: (data: any) => void;
}

export const JobDetails: React.FC<Props> = (props: Props) => {
  const [isJobDetails, setIsJobDetails] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState<any>([]);
  const [selectedTeamMemberList, setSelectedTeamMemberList] = useState<any>([]);
  const [selectedHiringManager, setSelectedHiringManager] = useState<any>([]);
  const companyUuid = sessionStorage.getItem('company_uuid') || '';
  const [selectedHiringManagerList, setSelectedHiringManagerList] = useState<any>([]);
  const [jobDetailsFormData, setJobDetailsFormData] = useState<FormBuilderData>({
    errors: [],
    isValid: false,
    value: {},
  });
  let { id, userId } = useParams<{
    id: string;
    userId: string;
  }>();
  const [selectedJobId, setSelectedJobId] = useState<any>(userId);
  const [currentJobData, setCurrentJobData] = useState<any>({});
  const [formError, setFormError] = useState<any>('');
  const [loading, setLoading] = useState(false);
  const [allMembers, setAllMembers] = useState<any[] | []>([]);
  const loginUserId = sessionStorage.getItem('userUuid') || '';
  const [seniorityError, setSeniorityError] = useState('');
  //   const [descriptionError, setDescriptionError] = useState("");
  //   const [customTagError, setCustomTagError] = useState("");
  //   const [smeInstructionsError, setSmeInstructionsError] = useState("");
  const [seniority, setSeniority] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [Instructions, setInstructions] = useState('');
  const [tagsData, setTagsData] = useState<any[]>([]);
  const [statusError, setStatusError] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [managersDatas, setManagersDatas] = useState<any[]>([]);
  const [showAvailabilityNotifications, setShowAvailabilityNotifications] = useState(false);
  const notificationref = useRef<any>(null);

  const handleInput = (data: any) => {
    data.value = {
      ...currentJobData,
      ...data.value,
    };
    setCurrentJobData(data.value);
    setFormError(false);
  };

  const onChangeSeniority = (event: any) => {
    currentJobData.seniority_code = event.target.value;
    setFormError('');
    setSeniority(event.target.value);
  };

  const onChipData = (data: any) => {
    setFormError('');
    setTagsData(data);
  };

  const onchangeHiringManagers = (data: any) => {
    setManagersDatas(data);
  };

  const onChangeDescription = (e: any) => {
    setFormError('');
    // setDescriptionError("");
    setJobDescription(e.target.value);
    // if (!e.target.value) {
    //   setDescriptionError("Please enter description");
    // }
  };
  const onChangeInstructions = (e: any) => {
    setFormError('');
    setInstructions(e.target.value);
  };

  const hidePlaceHolder = (e: any) => {
    e.target.placeholder = '';
  };

  const showPlaceHolder = (e: any, labelToShow: string) => {
    e.target.placeholder = labelToShow;
  };

  useEffect(() => {
    getAllTeamMembers();
  }, []);

  useEffect(() => {
    if (props.jobId) {
      getJobDetailsId();
      setJobDetailsFormData({
        errors: [],
        isValid: true,
        value: {},
      });
    }
  }, []);

  const getJobDetailsId = () => {
    JobsService.getJobsByUuid(props?.jobId).then((res) => {
      if (res?.error) {
        setLoading(false);
        toast.error(res?.error?.message);
      } else {
        res.job_due_dt = moment(res?.job_due_dt).format('YYYY-MM-DD');
        setCurrentJobData({ ...res });
        setTagsData(res?.tags);
        // setJobDescription(res?.job_description);
        // setInstructions(res?.instructions_to_sme);
      }
    });
  };

  const getJobsTeamMember = async (teamMembersToPopulate: any) => {
    JobsService.getJobsTeamMembers(props?.jobId).then((res) => {
      if (res.error) {
        toast.error(res?.error?.message);
      } else {
        res.forEach((element: any) => {
          element.fullName = `${element?.user_firstname} ${element?.user_lastname}`;
          element.label = `${element?.user_firstname} ${element?.user_lastname}`;
          element.value = `${element.user_uuid}`;
          element.uuid = `${element.user_uuid}`;
        });

        const team = res?.filter((data: any) => data?.type === 1);
        const hr = res?.filter((data: any) => data?.type === 2);
        const teamMembers: any = [];
        const hrMembers: any = [];
        team.forEach((element: any) => {
          teamMembers.push(element?.user_uuid);
        });
        hr.forEach((element: any) => {
          hrMembers.push(element?.user_uuid);
        });
        setSelectedTeamMember([...teamMembers]);
        setSelectedHiringManager([...hrMembers]);
        setSelectedTeamMemberList([...team]);
        setSelectedHiringManagerList([...hr]);
        const uniqueResultOne = teamMembersToPopulate.filter((teamMemberToPopulate: any) => {
          return !team.some((teamElement: any) => {
            return teamMemberToPopulate.uuid === teamElement.user_uuid;
          });
        });
        setAllMembers(uniqueResultOne);
      }
    });
  };

  const getAllTeamMembers = () => {
    setLoading(true);
    UsersService.getUsers(companyUuid, '').then(async (res) => {
      if (res.error) {
        setLoading(false);
        toast.error(res?.error?.message);
      } else {
        setLoading(false);
        res.forEach((element: any) => {
          element.fullName = `${element?.user_firstname} ${element?.user_lastname}`;
          element.label = `${element?.user_firstname} ${element?.user_lastname}`;
          element.value = element?.uuid;
          element.uuid = element?.uuid;
        });

        const teamMembersToPopulate = res.filter((data: any) => loginUserId !== data?.uuid);

        setAllMembers([...teamMembersToPopulate]);

        if (props.jobId) {
          await getJobsTeamMember(teamMembersToPopulate);
        }
      }
    });
  };

  const onChangeTeamMember = (selectedOption: any, actionMeta: any) => {
    switch (actionMeta.action) {
      case 'select-option':
        setSelectedTeamMemberList([...selectedTeamMemberList, actionMeta.option]);
        setSelectedTeamMember(selectedTeamMemberList);
        const newAllTeamMemberList = allMembers.filter(
          (element: any) => element.uuid !== actionMeta.option.uuid
        );
        setAllMembers([...newAllTeamMemberList]);
        break;
      case 'remove-value':
        const newSelectedTeamMemberList = selectedTeamMemberList.filter(
          (element: any) => element.uuid !== actionMeta.removedValue.uuid
        );
        setAllMembers([...allMembers, actionMeta.removedValue]);
        setSelectedTeamMemberList([...newSelectedTeamMemberList]);
        setSelectedTeamMember(selectedTeamMemberList);
        break;
    }
  };

  const onSelectHiringManager = (selectedList: any) => {
    setSelectedHiringManager([...selectedList]);
  };

  const onRemoveHiringManager = (selectedList: any, removedItem: any) => {
    const hrManagers: any = [];
    selectedList.map((hrManager: any) => {
      hrManagers.push(hrManager?.uuid);
    });
    setSelectedHiringManagerList([...selectedList]);
    setSelectedHiringManager([...hrManagers]);
  };

  const onChangeStatus = (event: any) => {
    currentJobData.status = event.target.value;
    setSelectedStatus(event.target.value);
    setFormError('');
  };

  const createJob = () => {
    setIsJobDetails(true);
    if (!jobDetailsFormData.isValid) {
      setFormError('Mandatory fields are not filled');
      return;
    }

    if (props.jobId) {
      const tags = tagsData.length > 0 ? tagsData.toString() : '';
      let addingData: any = {};

      addingData = {
        ...currentJobData,
        ...jobDetailsFormData.value,
      };
      const preparedData = {
        ...addingData,
        tags,
      };
      const teamMembersToUpdate = selectedTeamMemberList.map(
        (teamMemberToSendToDB: any) => teamMemberToSendToDB.uuid
      );
      preparedData.team_members = teamMembersToUpdate;
      preparedData.hr_managers = selectedHiringManager;
      preparedData.status = preparedData.status ? preparedData.status : 'active';
      preparedData.positions = Number(preparedData.positions);
      props.onSave(preparedData);
    } else {
      const tags = tagsData.length > 0 ? tagsData.toString() : '';
      const preparedData = {
        ...jobDetailsFormData.value,
        tags,
      };
      preparedData.team_members = selectedTeamMember;
      preparedData.hr_managers = selectedHiringManager;
      preparedData.positions = Number(preparedData.positions);
      preparedData.status = preparedData.status ? preparedData.status : 'active';
      props.onSave(preparedData);
    }
  };

  const onShowAvailabilityNotification = () => {
    setShowAvailabilityNotifications(!showAvailabilityNotifications);
    // setShowNotifications(true);
    // setShowProfile(false);
  };

  return (
    <>
      {loading && <AppLoader loading={loading}></AppLoader>}
      <FormBuilder onUpdate={setJobDetailsFormData} showValidations={isJobDetails}>
        <form>
          <div className='container-fluid'>
            <div className='row mt-5'>
              {/* Job Title */}
              <div className='col-md-6 mb_22 pe-lg-5'>
                <label className='input'>
                  <input
                    type='text'
                    className='form-control job_dis_form_control px-3 rounded manual_profile_padding input__field'
                    id='jobTitle'
                    name='job_title'
                    placeholder={`${labels.RECRUITER_JOB_TITLE_LABEL} *`}
                    data-validate-required='Please enter job title'
                    defaultValue={currentJobData?.job_title}
                    onFocus={(e) => hidePlaceHolder(e)}
                    onBlur={(e) => showPlaceHolder(e, labels.RECRUITER_JOB_TITLE_LABEL + ' *')}
                  />
                  <span className={`input__label input__label_disabled`}>
                    {labels.RECRUITER_JOB_TITLE_LABEL}
                    <span className='text-danger'>*</span>
                  </span>
                </label>
              </div>

              {/* <div className="col-md-6 mb_22 ps-lg-3">
                <label className="input">
                  <select
                    className="form-select job_dis_form_control px-3 rounded manual_profile_padding down_arrow_bg_img input__field"
                    // aria-label="Default select example"
                    name="status"
                    value={currentJobData?.status}
                    onChange={(event) => onChangeStatus(event)}
                    // onBlur={() =>
                    //   autoSaveJob(
                    //     currentJobData?.status
                    //       ? currentJobData?.status
                    //       : "draft"
                    //   )
                    // }
                  >
                    <option value="draft" selected>
                      Draft
                    </option>
                    <option value="active">Active</option>
                    <option value="archived">Archived</option>
                  </select>
                  <span className="input__label">
                    Status <span className="text-danger">*</span>
                  </span>
                </label>
              </div> */}

              {/* Positions */}
              <div className='col-md-6 mb_22 ps-lg-3'>
                <label className='input'>
                  <input
                    type='number'
                    className='form-control job_dis_form_control px-3 rounded manual_profile_padding input__field'
                    id='positions'
                    name='positions'
                    placeholder={`${labels.RECRUITER_POSITIONS_LABEL} *`}
                    data-validate-required='Please enter positions'
                    defaultValue={currentJobData?.positions}
                    onFocus={(e) => hidePlaceHolder(e)}
                    onBlur={(e) => showPlaceHolder(e, labels.RECRUITER_POSITIONS_LABEL + ' *')}
                  />
                  <span className={`input__label input__label_disabled`}>
                    {labels.RECRUITER_POSITIONS_LABEL}
                    <span className='text-danger'>*</span>
                  </span>
                </label>
              </div>

              {/* Location */}
              <div className='col-md-6 mb_22 pe-lg-5'>
                <label className='input'>
                  <input
                    type='text'
                    className='form-control job_dis_form_control px-3 rounded manual_profile_padding input__field'
                    id='location'
                    name='location'
                    placeholder={`${labels.RECRUITER_LOCATION_LABEL} *`}
                    data-validate-required='Please enter location'
                    defaultValue={currentJobData?.location}
                    onFocus={(e) => hidePlaceHolder(e)}
                    onBlur={(e) => showPlaceHolder(e, labels.RECRUITER_LOCATION_LABEL + ' *')}
                  />
                  <span className={`input__label input__label_disabled`}>
                    {labels.RECRUITER_LOCATION_LABEL}
                    <span className='text-danger'>*</span>
                  </span>
                </label>
              </div>

              {/* Seniority Level */}
              <div className='col-md-6 col-12 mb_22 ps-lg-3'>
                <label className='input'>
                  <select
                    className='form-select job_dis_form_control px-3 rounded manual_profile_padding down_arrow_bg_img input__field'
                    name='seniority_code'
                    onChange={(event) => onChangeSeniority(event)}
                    value={currentJobData.seniority_code}
                  >
                    <option value={'Trainee'}>Trainee</option>
                    <option value={'Junior'}>Junior</option>
                    <option value={'Middle'}>Middle</option>
                    <option value={'Senior'}>Senior</option>
                  </select>
                  <span className={`input__label input__label_disabled`}>
                    Seniority
                    <span className='text-danger'>*</span>
                  </span>
                </label>
              </div>

              {/* Recruiting for Company*/}
              <div className='col-md-6 col-12 mb_22 pe-lg-5'>
                <label className='input'>
                  <input
                    type='text'
                    className='form-control job_dis_form_control px-3 rounded manual_profile_padding input__field'
                    id='recruiting_for_company'
                    name='recruiting_for_company'
                    placeholder={`${labels.RECRUITER_RECRUITING_FOR_COMPANY_LABEL} *`}
                    data-validate-required='Please mention the company you are recruiting for'
                    defaultValue={currentJobData?.recruiting_for_company}
                    onFocus={(e) => hidePlaceHolder(e)}
                    onBlur={(e) =>
                      showPlaceHolder(e, labels.RECRUITER_RECRUITING_FOR_COMPANY_LABEL + ' *')
                    }
                  />
                  <span className={`input__label input__label_disabled`}>
                    {labels.RECRUITER_RECRUITING_FOR_COMPANY_LABEL}
                    <span className='text-danger'>*</span>
                  </span>
                </label>
              </div>

              {/* Recruiting for Team */}
              <div className='col-md-6 mb_22 ps-lg-3'>
                <label className='input'>
                  <input
                    type='text'
                    className='form-control job_dis_form_control px-3 rounded manual_profile_padding input__field'
                    id='recruiting_for_team'
                    name='recruiting_for_team'
                    placeholder={`${labels.RECRUITER_RECRUITING_FOR_TEAM_LABEL} *`}
                    data-validate-required='Please mention the company you are recruiting for'
                    defaultValue={currentJobData?.recruiting_for_team}
                    onFocus={(e) => hidePlaceHolder(e)}
                    onBlur={(e) =>
                      showPlaceHolder(e, labels.RECRUITER_RECRUITING_FOR_TEAM_LABEL + ' *')
                    }
                  />
                  <span className={`input__label input__label_disabled`}>
                    {labels.RECRUITER_RECRUITING_FOR_TEAM_LABEL}
                    <span className='text-danger'>*</span>
                  </span>
                </label>
              </div>

              <div className='col-md-6 mb_22 pe-lg-5'>
                <label className='input'>
                  <input
                    type='date'
                    className='form-control job_dis_form_control px-3 rounded manual_profile_padding input__field'
                    min={moment().add(1, 'day').format('YYYY-MM-DD')}
                    id='dueDate'
                    name='job_due_dt'
                    placeholder='Due Date *'
                    data-validate-required='Please enter due date'
                    defaultValue={currentJobData?.job_due_dt}
                  />

                  <span className={`input__label input__label_disabled`}>
                    Due Date
                    <span className='text-danger'>*</span>
                  </span>
                </label>
              </div>
              <div className='col-md-6 mb_22 ps-lg-3'>
                <label className='input'>
                  <textarea
                    className='form-control job_dis_form_control px-3 rounded manual_profile_padding input__field'
                    id='description'
                    name='job_description'
                    placeholder={labels.RECRUITER_DESCRIPTION_LABEL}
                    style={{ paddingTop: '15px' }}
                    defaultValue={currentJobData.job_description}
                    onChange={(e) => onChangeDescription(e)}
                    onFocus={(e) => hidePlaceHolder(e)}
                    onBlur={(e) => showPlaceHolder(e, labels.RECRUITER_DESCRIPTION_LABEL)}
                  ></textarea>
                  <span className={`input__label input__label_disabled`}>
                    {labels.RECRUITER_DESCRIPTION_LABEL}
                  </span>
                </label>
              </div>
              {/* <div className='col-md-12 mb_22'>
                <label className='input'>
                  <textarea
                    className='form-control job_dis_form_control px-3 rounded manual_profile_padding input__field'
                    id='InstructionstoSMEs'
                    name='instructions_to_sme'
                    placeholder={labels.RECRUITER_INSTRUCTIONS_LABEL}
                    style={{ paddingTop: '15px' }}
                    defaultValue={currentJobData?.instructions_to_sme}
                    onChange={(e) => onChangeInstructions(e)}
                    onFocus={(e) => hidePlaceHolder(e)}
                    onBlur={(e) =>
                      showPlaceHolder(e, labels.RECRUITER_INSTRUCTIONS_LABEL)
                    }
                  ></textarea>
                  <span className={`input__label input__label_disabled`}>
                    {labels.RECRUITER_INSTRUCTIONS_LABEL}
                  </span>
                </label>
                 {smeInstructionsError && (
                  <p className="text-danger job_dis_form_label">
                    {smeInstructionsError}
                  </p>
                )} 
              </div> */}
              <div className='col-md-6 mb_22 pe-lg-5'>
                <div className='row'>
                  <div className='col-md-10 col-9'>
                    {/* <ChipInput
                      type={"text"}
                      placeholder="Add Custom Tags *"
                      isOutside={true}
                      outSideText={""}
                      getChipsFieldData={(data) => onChipData(data)}
                    ></ChipInput> */}
                    {/* {currentJobData?.tags && (
                      <ChipInput
                        type={'text'}
                        placeholder='Add Custom Tags'
                        items={currentJobData?.tags}
                        isOutside={true}
                        outSideText={''}
                        getChipsFieldData={(data) => onChipData(data)}
                      ></ChipInput>
                    )} */}
                    {/* {!currentJobData?.tags && (
                      <ChipInput
                        type={'text'}
                        placeholder='Add Custom Tags'
                        items={currentJobData?.tags}
                        isOutside={true}
                        outSideText={''}
                        getChipsFieldData={(data) => onChipData(data)}
                        isAdd={true}
                      ></ChipInput>
                    )} */}
                  </div>
                  {/* <div className='col-md-2 mt-2 mt-lg-1 col-3 text-end pe-3 pe-lg-4'> */}
                  {/* <img
                      src={ADD_ICON}
                      alt="info icon"
                      className="ps-lg-3 ps-4" 
                    /> */}

                  {/* <span
                      className=' sx-text-primary pointer mobile_info position-relative'
                      onClick={() => onShowAvailabilityNotification()}
                      onMouseLeave={() =>
                        setShowAvailabilityNotifications(false)
                      }
                      onMouseEnter={onShowAvailabilityNotification}
                    >
                      <img
                        src={INFO_ICON}
                        alt='info icon'
                        className=' mobile_info  pt-1 '
                      />
                    </span> */}
                  {/* </div> */}
                  {/* <div className='position-relative'>
                    {showAvailabilityNotifications && (
                      <div
                        onMouseEnter={onShowAvailabilityNotification}
                        className='rounded-3 availability_modal '
                        ref={notificationref}
                        onMouseLeave={() =>
                          setShowAvailabilityNotifications(false)
                        }
                        style={{
                          zIndex: 999,
                          top: '-7px',
                        }}
                      >
                        <div className='row '>
                          <div className='col-md-12 top_para_styles mt-2 mb-2 pt-3 ps-4 px-lg-3'>
                            <p className='availability_tooltip_fontsize'>
                              {' '}
                              You can add custom tags to jobs to be able to
                              identify them easily and filter jobs based on the
                              tags. E.g. Particular team in a client company,
                              Partner name who referred the job etc.
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div> */}
                </div>
              </div>
              <div className='col-md-12 mb_22'>
                {/* <label className='input'>
                  <textarea
                    className='form-control job_dis_form_control px-3 rounded manual_profile_padding input__field'
                    id='InstructionstoSMEs'
                    name='instructions_to_sme'
                    placeholder={labels.RECRUITER_INSTRUCTIONS_LABEL}
                    style={{ paddingTop: '15px' }}
                    defaultValue={currentJobData?.instructions_to_sme}
                    onChange={(e) => onChangeInstructions(e)}
                    onFocus={(e) => hidePlaceHolder(e)}
                    onBlur={(e) =>
                      showPlaceHolder(e, labels.RECRUITER_INSTRUCTIONS_LABEL)
                    }
                  ></textarea>
                  <span className={`input__label input__label_disabled`}>
                    {labels.RECRUITER_INSTRUCTIONS_LABEL}
                  </span>
                </label> */}
                {/* {smeInstructionsError && (
                  <p className="text-danger job_dis_form_label">
                    {smeInstructionsError}
                  </p>
                )} */}
              </div>

              {/* Adding Team Members */}
              <div className='col-md-6 col-12 mb_22 pe-lg-5'>
                <Select
                  isMulti={true}
                  value={selectedTeamMemberList}
                  placeholder='Add Team Members To This Job'
                  onChange={onChangeTeamMember}
                  options={allMembers}
                  className='search_dropdown'
                />
              </div>

              {/* Tags */}
              <div className='col-md-6 col-12  mb_22 ps-lg-3'>
                {/* <Select
                  isMulti={true}
                  //   value={selectedTeamMeberList}
                  placeholder="  Add Hiring Manager To This Project"
                  //   onChange={(e) => onSelectHiringManager(e)}
                  //   options={allMembers}
                  className="search_dropdown"
              /> */}

                <div className='row'>
                  <div className='col-md-10 col-9'>
                    {currentJobData?.tags && (
                      <ChipInput
                        type={'text'}
                        placeholder='Add Custom Tags'
                        items={currentJobData?.tags}
                        isOutside={true}
                        outSideText={''}
                        getChipsFieldData={(data) => onChipData(data)}
                        isAdd={true}
                      ></ChipInput>
                    )}
                    {!currentJobData?.tags && (
                      <ChipInput
                        type={'text'}
                        placeholder='Add Custom Tags'
                        items={''}
                        isOutside={true}
                        outSideText={''}
                        getChipsFieldData={(data) => onChipData(data)}
                        isAdd={true}
                      ></ChipInput>
                    )}
                  </div>
                  <div className='col-md-2 mt-2 mt-lg-1 col-3 text-end pe-3 pe-lg-4'>
                    {/* <img
                      src={ADD_ICON}
                      alt="info icon"
                      className="ps-lg-3 ps-4" 
                    /> */}
                    <span
                      className=' sx-text-primary pointer mobile_info position-relative'
                      onClick={() => onShowAvailabilityNotification()}
                      onMouseLeave={() => setShowAvailabilityNotifications(false)}
                      onMouseEnter={onShowAvailabilityNotification}
                    >
                      <img src={INFO_ICON} alt='info icon' className=' mobile_info  pt-1 ' />
                    </span>
                  </div>
                  <div className='position-relative'>
                    {showAvailabilityNotifications && (
                      <div
                        onMouseEnter={onShowAvailabilityNotification}
                        className='rounded-3 availability_modal '
                        ref={notificationref}
                        onMouseLeave={() => setShowAvailabilityNotifications(false)}
                        style={{
                          zIndex: 999,
                          top: '-7px',
                        }}
                      >
                        <div className='row '>
                          <div className='col-md-12 top_para_styles mt-2 mb-2 pt-3 ps-4 px-lg-3'>
                            <p className='availability_tooltip_fontsize'>
                              {' '}
                              You can add custom tags to jobs to be able to identify them easily and
                              filter jobs based on the tags. E.g. Particular team in a client
                              company, Partner name who referred the job etc.
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* InstructionstoSMEs */}
              <div className='col-md-12 mb_22'>
                <label className='input'>
                  <textarea
                    className='form-control job_dis_form_control px-3 rounded manual_profile_padding input__field'
                    id='InstructionstoSMEs'
                    name='instructions_to_sme'
                    placeholder={labels.RECRUITER_INSTRUCTIONS_LABEL}
                    style={{ paddingTop: '15px' }}
                    defaultValue={currentJobData?.instructions_to_sme}
                    onChange={(e) => onChangeInstructions(e)}
                    onFocus={(e) => hidePlaceHolder(e)}
                    onBlur={(e) => showPlaceHolder(e, labels.RECRUITER_INSTRUCTIONS_LABEL)}
                  ></textarea>
                  <span className={`input__label input__label_disabled`}>
                    {labels.RECRUITER_INSTRUCTIONS_LABEL}
                  </span>
                </label>
                {/* {smeInstructionsError && (
                  <p className="text-danger job_dis_form_label">
                    {smeInstructionsError}
                  </p>
                )} */}
              </div>

              {/* <div className="col-md-6 mb_22 pe-lg-5">
                <div className="row">
                  <div className="col-md-10 col-9">
                    <ChipInput
                      type={"text"}
                      placeholder="Add Custom Tags *"
                      isOutside={true}
                      outSideText={""}
                      getChipsFieldData={(data) => onChipData(data)}
                    ></ChipInput>
                    {currentJobData?.tags && (
                      <ChipInput
                        type={"text"}
                        placeholder="Add Custom Tags"
                        items={currentJobData?.tags}
                        isOutside={true}
                        outSideText={""}
                        getChipsFieldData={(data) => onChipData(data)}
                      ></ChipInput>
                    )}
                    {!currentJobData?.tags && (
                      <ChipInput
                        type={"text"}
                        placeholder="Add Custom Tags"
                        items={currentJobData?.tags}
                        isOutside={true}
                        outSideText={""}
                        getChipsFieldData={(data) => onChipData(data)}
                        isAdd={true}
                      ></ChipInput>
                    )}
                  </div>
                  <div className="col-md-2 mt-2 mt-lg-1 col-3 text-end pe-3 pe-lg-4">
                    <img
                      src={ADD_ICON}
                      alt="info icon"
                      className="ps-lg-3 ps-4"
                    />

                    <span
                      className=" sx-text-primary pointer mobile_info position-relative"
                      onClick={() => onShowAvailabilityNotification()}
                      onMouseLeave={() =>
                        setShowAvailabilityNotifications(false)
                      }
                      onMouseEnter={onShowAvailabilityNotification}
                    >
                      <img
                        src={INFO_ICON}
                        alt="info icon"
                        className=" mobile_info  pt-1 "
                      />
                    </span>
                  </div>
                  <div className="position-relative">
                    {showAvailabilityNotifications && (
                      <div
                        onMouseEnter={onShowAvailabilityNotification}
                        className="rounded-3 availability_modal "
                        ref={notificationref}
                        onMouseLeave={() =>
                          setShowAvailabilityNotifications(false)
                        }
                        style={{
                          zIndex: 999,
                          top: "-7px",
                        }}
                      >
                        <div className="row ">
                          <div className="col-md-12 top_para_styles mt-2 mb-2 pt-3 ps-4 px-lg-3">
                            <p className="availability_tooltip_fontsize">
                              {" "}
                              You can add custom tags to jobs to be able to
                              identify them easily and filter jobs based on the
                              tags. E.g. Particular team in a client company,
                              Partner name who referred the job etc.
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div> */}

              {/* <div className="col-md-12 mb_22">
                <label className='input'>
                  <textarea
                    className='form-control job_dis_form_control px-3 rounded manual_profile_padding input__field'
                    id='InstructionstoSMEs'
                    name='instructions_to_sme'
                    placeholder={labels.RECRUITER_INSTRUCTIONS_LABEL}
                    style={{ paddingTop: '15px' }}
                    defaultValue={currentJobData?.instructions_to_sme}
                    onChange={(e) => onChangeInstructions(e)}
                    onFocus={(e) => hidePlaceHolder(e)}
                    onBlur={(e) =>
                      showPlaceHolder(e, labels.RECRUITER_INSTRUCTIONS_LABEL)
                    }
                  ></textarea>
                  <span className={`input__label input__label_disabled`}>
                    {labels.RECRUITER_INSTRUCTIONS_LABEL}
                  </span>
                </label>
                {smeInstructionsError && (
                  <p className="text-danger job_dis_form_label">
                    {smeInstructionsError}
                  </p>
                )}
              </div> */}
            </div>
          </div>
        </form>
      </FormBuilder>
      {/* <div className="row">
        <div className="col-md-12 mb_22">
          <div className="text-end mt-4">
            <button
              className="large_btn_apply rounded"
              type="button"
              onClick={() => createJob()}
            >
              Save & Next
            </button>
            {formError && (
              <p className="text-danger job_dis_form_label">{formError}</p>
            )}
          </div>
        </div>
      </div> */}
      <div
        className='position-absolute px-3 px-lg-5 bottom-30 bottom-sm-20'
        style={{ width: '100%', left: 0 }}
      >
        <div className='text-end mt-5 mt-lg-0 mt-sm-4 pe-2'>
          {formError && <small className='text-danger me-3'>Mandatory fields are not filled</small>}
          <button
            className='large_btn_apply rounded me-2'
            type='button'
            onClick={() => createJob()}
          >
            Save & Next
          </button>
        </div>
      </div>
    </>
  );
};
