import React, { useRef } from "react";
import { SliderComponent } from "./slider";
import { SmesCards } from './smes-cards';

interface Props {
  jobId: string;
  onClose: () => void;
  // onSave: (data: any) => void;
  isbutton: boolean; // Toggles to true when used in Job Creation Popup
}

export const Smes: React.FC<Props> = (props: Props) => {

  const slidersData = {
    experienceMinValue: 5,
    experienceMaxValue: 15,
    rateMinValue: 50,
    rateMaxValue: 150
  }

  const sliderRef: any = useRef()

  // Triggers whenever rate or experience sliders are updated
  const onSliderChange = (val: any) => {
    if (val.type == 'experience') {
      slidersData.experienceMinValue = val.minValue;
      slidersData.experienceMaxValue = val.maxValue;
    }

    if (val.type == 'rate') {
      slidersData.rateMinValue = val.minValue
      slidersData.rateMaxValue = val.maxValue
    }

    sliderRef.current.onSliderUpdate(slidersData)
  }

  return (
    <div className="row">
      <div className="col-9 mt-50">
        <SmesCards jobId={props.jobId}
          onClose={() => props.onClose()}
          isbutton={props.isbutton}
          slidersData={slidersData}
          ref={sliderRef}
        ></SmesCards>
      </div>
      {props.isbutton === false && (
        <div className="col-md-3 col-sm-12 mt-200">
          <div className="mx-4 mt-4 mt-lg-0">
            <SliderComponent onSliderChange={onSliderChange} />
          </div>
        </div>
      )}
    </div>
  )
};