export const labels = {
  SME_SELECT_ALL_TOOL_TIP:
    'This selects all the SMEs shown on this page. Please scroll down to view all',
  SME_AVAILABLE_IMMEDIATELY_TOOL_TIP:
    'This will show only those SMEs who are immediately available to interview in the availability slots of the candidates',
  SME_MY_FAVOURITES_TOOL_TIP:
    'This will show only the SMEs you have selected as your favourites',
  RECRUITER_JOB_TITLE_LABEL: 'Job Title',
  RECRUITER_POSITIONS_LABEL: 'Positions',
  RECRUITER_LOCATION_LABEL: 'Location',
  RECRUITER_RECRUITING_FOR_COMPANY_LABEL: 'Client Recruiting For',
  RECRUITER_RECRUITING_FOR_TEAM_LABEL: 'Team Recruiting For',
  RECRUITER_DESCRIPTION_LABEL: 'Description',
  RECRUITER_INSTRUCTIONS_LABEL: 'Instructions to SMEs',
  CANDIDATE_FIRST_NAME_LABEL: 'First Name',
  CANDIDATE_LAST_NAME_LABEL: 'Last Name',
  CANDIDATE_EMAIL_LABEL: 'Email',
  CANDIDATE_LINKEDIN_LABEL: 'LinkedIn',
};
