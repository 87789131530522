import React, { useEffect, useState } from 'react';
import ZoomMtgEmbedded from '@zoomus/websdk/embedded';
import { toast } from 'react-toastify';
import { SmeService } from '../app/service/sme.service';
import { useHistory } from 'react-router-dom';
import { zoomSdkKey, zoomSdkSecret } from '../config/constant';
import { AppLoader } from './loader';
import moment from 'moment';

const ZoomClient = ZoomMtgEmbedded.createClient();

interface Props {
  zoomId: string;
  interview: string;
  scheduleTime: string;
}
export const ZoomInterviewMeeting: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const [isJoinMeating, setIsJoinMeating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentTime, setCurrentTime] = useState(false);

  var role = 1;
  var userName = 'testadmin@siftedx.com';

  // Loads this package unless user opens this page
  async function createMeetingLayout() {
    // This package is exposing css styles globally, this overrides application css properties across the app
    var { ZoomMtg } = await import('@zoomus/websdk');

    // Start meeting only after loading 'meetingSDKElement'
    setTimeout(() => {
      joinMeeting(ZoomMtg);
    }, 5000);
  }

  useEffect(() => {
    intializeEmbedMeeting();
    createMeetingLayout();
  }, []);

  const intializeEmbedMeeting = async () => {
    let meetingSDKElement = document.getElementById('meetingSDKElement');
    if (meetingSDKElement) {
      ZoomClient.init({
        debug: true,
        zoomAppRoot: meetingSDKElement,
        language: 'en-US',
        customize: {
          meetingInfo: [
            'topic',
            'host',
            'mn',
            'pwd',
            'telPwd',
            'invite',
            'participant',
            'dc',
            'enctype',
          ],
          toolbar: {
            buttons: [
              {
                text: 'Record',
                className: 'CustomButton',
                onClick: () => {
                  ZoomClient.record('start');
                },
              },
            ],
          },
          video: {
            viewSizes: {
              default: {
                height: 500,
                width: 500,
              },
            },
          },
        },
      });
    }
  };

  const joinMeeting = async (ZoomMtg: any) => {
    ZoomClient.endMeeting(); // This will close existing zoom client connections if any
    const meetingNumber = props.zoomId; //meetingId.current?.value;
    if (meetingNumber) {
      const signature = ZoomMtg.generateSDKSignature({
        meetingNumber: meetingNumber,
        sdkKey: zoomSdkKey,
        sdkSecret: zoomSdkSecret,
        role: role.toString(),
      });
      ZoomClient.join({
        sdkKey: zoomSdkKey,
        signature: signature, // role in signature needs to be 1
        meetingNumber,
        userName: userName,
      });
      setIsJoinMeating(true);
    }
  };

  const onEndCall = () => {
    const interviewStartTime: any = new Date(props.scheduleTime);
    const canCloseInterview = new Date() >= moment(interviewStartTime).add(10, 'minutes').toDate();
    if (canCloseInterview) {
      setLoading(true);
      ZoomClient.endMeeting();
      SmeService.completeInterview(props?.interview).then((res) => {
        setLoading(false);
        if (res.error) {
          toast.error(res?.error?.message);
        } else {
          history.push(`/dashboard/interviews/evaluation-report/${props?.interview}`);
        }
      });
    } else toast.error('You cannot complete the interview within the first 10mins ');
  };
  return (
    <>
      {loading && <AppLoader loading={loading}></AppLoader>}
      <div
        style={{
          position: 'relative',
        }}
      >
        <div id='meetingSDKElement' style={{ position: 'relative', display: 'block' }}></div>
        {isJoinMeating && (
          <button className='btn btn-danger' onClick={() => onEndCall()}>
            Complete the Interview
          </button>
        )}
      </div>
    </>
  );
};
