import React, { useEffect, useState, useRef } from "react";
import PhoneInput from "react-phone-input-2";
import UploadCV from "../../assets/icon_images/Upload CV.png";
import OpenCV from "../../assets/icon_images/Open CV.png";
import { CLOUDFRONT_URL } from "../../config/constant";
import {
  countryCodeValidations,
  emialValidations,
  linkedinValidations,
  mobileNumberValidations,
  nameValidations,
} from "../../app/utility/form-validations";
import FormBuilder, { FormBuilderData } from "../../components/form-builder";
import ADD_ICON from "../../assets/icon_images/Add.svg";
import DELETE_ICON from "../../assets/icon_images/delete.svg";
import { LookUpService } from "../../app/service/lookup.service";
import ChipInput from "../../components/chip-input";
import INFO_ICON from "../../assets/icon_images/info icon.svg";
import moment from "moment";
import { labels } from "../../app/utility/constants";
import Creatable from "react-select/creatable";
import CreatableSelect from "react-select/creatable";

interface Props {
  // countryData: any;
  // skills: any;
  onUploadResume: any;
  resumeUrl: any;
  onSave: (data: any) => void;
  isShowCancelPopup?: boolean;
  onCloseDiscardPopup?: (data: any) => void;
  onClose?: () => void;
  candidateData?: any;
}

const Addcandidate: React.FC<Props> = (props: any) => {
  const [isCandidateSubmitted, setIsCandidateSubmitted] = useState(false);
  const [formError, setFormError] = useState("");
  const [skillError, setSkillError] = useState("");
  const [mandatorySkills, setMandatorySkills] = useState<any[] | []>([]);
  const [skills, setSkills] = useState<any[]>([]);
  const [experienceList, setExperienceList] = useState<any[]>([]);
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [mobileNumberCountryCodeError, setMobileNumberCountryCodeError] =
    useState("");
  const [selectedJoiningAvailability, setSelectedJoiningAvailability] =
    useState(3);
  const [countryData, setCountryData] = useState<any[] | []>([]);
  const [currentSkill, setCurrentSkill] = useState<any>({
    skill: "",
    experience: "",
    proficiency: "",
    input: "",
  });
  const [tagsData, setTagsData] = useState<any[]>([]);
  const [candidateFormData, setCandidateFormData] = useState<FormBuilderData>({
    errors: [],
    isValid: false,
    value: {
      mobile_no: "+1 (121) 212-12122",
      user_email: "asdaskda@gmail.com",
      user_firstname: "qwdqladm",
      user_lastname: "as,dnasd",
    },
  });
  const [firstname, setFirstname] = useState(
    props?.candidateData?.user_firstname ?? ""
  );
  const [lastname, setLastname] = useState(
    props?.candidateData?.user_lastname ?? ""
  );
  const [mobileno, setMobileno] = useState(
    props?.candidateData?.mobile_no ?? ""
  );
  const [email, setEmail] = useState(props?.candidateData?.user_email ?? "");
  const [linkedinUrl, setLinkedinUrl] = useState(
    props?.candidateData?.linkedin_url ?? ""
  );
  const [availabilitytime, setAvailabilitytime] = useState(
    props?.candidateData?.availability_time ?? ""
  );

  console.log(props);

  var selectRef = null;

  const [showAvailabilityNotifications, setShowAvailabilityNotifications] =
    useState(false);
  const notificationref = useRef<any>(null);

  useEffect(() => {
    const experience = [];
    for (let index = 0; index <= 30; index++) {
      experience.push(index);
      setExperienceList([...experience]);
    }
    loadJobskills();
    loadCountries();
    if (props.candidateData) {
      candidateFormData.isValid = true;
      const skills_codes = props.candidateData.skills_codes.split(",");
      const skills_exp = props.candidateData.skills_exp.split(",");
      const skills_proficiency =
        props.candidateData.skills_proficiency.split(",");

      const skillsMap = [];

      for (let index = 0; index < skills_codes.length; index++) {
        const element: any = {};
        element.skill = skills_codes[index];
        element.experience = skills_exp[index];
        element.proficiency = skills_proficiency[index];
        skillsMap.push(element);
      }
      console.log(skillsMap);
      setMandatorySkills(skillsMap);
    }
  }, []);

  // useEffect(() => {
  //   const activeSkills = mandatorySkills.filter((skill: any) => {
  //     if (skill.skill) return skill;
  //   }).length;
  //   if (props.isShowCancelPopup !== false) {
  //     if (
  //       candidateFormData.value.user_firstname ||
  //       candidateFormData.value.user_lastname ||
  //       candidateFormData.value.mobile_no ||
  //       candidateFormData.value.user_email ||
  //       candidateFormData.value.linkedin_url ||
  //       candidateFormData.value.availability_time ||
  //       activeSkills !== 0 ||
  //       props.resumeUrl
  //     )
  //       props.onCloseDiscardPopup(true);
  //     else props.onCloseDiscardPopup("close-main-popup");
  //   }
  // }, [props.isShowCancelPopup]);

  const loadJobskills = () => {
    LookUpService.getAllSkills().then((res) => {
      setSkills(
        res.map((el) => {
          return {
            value: el.skill,
            label: el.skill,
          };
        })
      );
    });
  };

  const loadCountries = () => {
    LookUpService.getCountry().then((res) => {
      // setCountryesData(res);
      setCountryData(res);
    });
  };

  const onChangeMobileNumber = (event: any) => {
    setMobileNumberError(mobileNumberValidations(event, "mobile number"));
  };

  const onChangeMobileNumberCountryCode = (event: any) => {
    setMobileNumberCountryCodeError(
      countryCodeValidations(event.target.value, "country code")
    );
  };

  const onAddSkills = () => {
    const data: any = mandatorySkills;
    data.push({
      skill: currentSkill.skill.value,
      experience: currentSkill.experience,
      proficiency: currentSkill.proficiency,
    });
    setMandatorySkills([...data]);
    setCurrentSkill({
      skill: "",
      experience: "",
      proficiency: "",
      input: "",
    });
  };

  const onChanegMandatorySkill = (e: any, index: number) => {
    console.log(e, index);
    const data: any = mandatorySkills;
    data[index].skill = e.value;
    setMandatorySkills([...data]);
    console.log(mandatorySkills);
  };

  const onChanegMandatorySkillExperie = (e: any, index: number) => {
    const data: any = mandatorySkills;
    data[index].experience = e.target.value;
    setMandatorySkills([...data]);
  };

  const onSelectProficiency = (event: any, index: number) => {
    // if (event.target.value) {
    const data = mandatorySkills;
    data[index].proficiency = event.target.value;
    setMandatorySkills([...data]);
    // }
  };
  const onDeleteSkill = (index: number) => {
    const data = mandatorySkills;
    data.splice(index, 1);
    setMandatorySkills([...data]);
  };

  const createUpdateCandidate = () => {
    setIsCandidateSubmitted(true);
    if (!candidateFormData.isValid) {
      setFormError("Mandatory fields are not filled");
      return;
    }
    if (mandatorySkills.length <= 0) {
      setFormError("Mandatory fields are not filled");
      setSkillError("Please select skills");
      return;
    }
    const skills_codes = mandatorySkills.map((el) => el.skill).toString();
    const skills_exp = mandatorySkills.map((el) => el.experience).toString();
    const skills_proficiency = mandatorySkills
      .map((el) => el.proficiency)
      .toString();
    const tags = tagsData.length > 0 ? tagsData.toString() : "";

    const preparedData = {
      ...candidateFormData.value,
      skills_codes,
      skills_exp,
      skills_proficiency,
      tags,
    };
    if (props.candidateData) {
      preparedData.uuid = props.candidateData.uuid;
      preparedData.user_firstname = firstname;
      preparedData.user_lastname = lastname;
      preparedData.user_email = email;
      preparedData.mobile_no = mobileno;
      preparedData.linkedin_url = linkedinUrl;
      preparedData.availability_time = availabilitytime;
      preparedData.resume_urls = "";
      preparedData.photo_url = "";
    }
    props.onSave(preparedData);
  };

  const onChipData = (data: any) => {
    setTagsData(data);
  };

  const onShowAvailabilityNotification = () => {
    setShowAvailabilityNotifications(!showAvailabilityNotifications);
  };

  const hidePlaceHolder = (e: any) => {
    e.target.placeholder = "";
  };

  const showPlaceHolder = (e: any, labelToShow: string) => {
    e.target.placeholder = labelToShow;
  };

  return (
    <>
      <FormBuilder
        onUpdate={setCandidateFormData}
        showValidations={isCandidateSubmitted}
      >
        <form>
          <div className="row">
            <div className="col-12 col-md-6 px-2">
              <div className="mb-4">
                <label className="input">
                  <input
                    type="text"
                    className="form-control job_dis_form_control rounded manual_profile_padding input__field"
                    placeholder={labels.CANDIDATE_FIRST_NAME_LABEL}
                    name="user_firstname"
                    value={firstname}
                    onInput={(val: any) => setFirstname(val.target.value)}
                    data-validate-required="Please enter your first name"
                    data-validate-name="Special characters are not allowed"
                    onFocus={(e) => hidePlaceHolder(e)}
                    onBlur={(e) =>
                      showPlaceHolder(
                        e,
                        labels.CANDIDATE_FIRST_NAME_LABEL + " *"
                      )
                    }
                  />
                  <span className={`input__label`}>
                    {labels.CANDIDATE_FIRST_NAME_LABEL}
                    <span
                      style={{
                        color: "red",
                        fontSize: "15px",
                        paddingLeft: "5px",
                      }}
                    >
                      *
                    </span>
                  </span>
                </label>
              </div>
            </div>
            <div className="col-12 col-md-6 px-2">
              <div className="mb-4">
                <label className="input">
                  <input
                    className="form-control job_dis_form_control rounded manual_profile_padding input__field"
                    placeholder={labels.CANDIDATE_LAST_NAME_LABEL}
                    type="text"
                    name="user_lastname"
                    value={lastname}
                    onInput={(val: any) => setLastname(val.target.value)}
                    data-validate-required="Please enter your last name"
                    data-validate-name="Special characters are not allowed"
                    onFocus={(e) => hidePlaceHolder(e)}
                    onBlur={(e) =>
                      showPlaceHolder(
                        e,
                        labels.CANDIDATE_LAST_NAME_LABEL + " *"
                      )
                    }
                  />
                  <span className={`input__label`}>
                    {labels.CANDIDATE_LAST_NAME_LABEL}
                    <span
                      style={{
                        color: "red",
                        fontSize: "15px",
                        paddingLeft: "5px",
                      }}
                    >
                      *
                    </span>
                  </span>
                </label>
              </div>
            </div>

            <div className="col-12 col-md-6 px-2">
              <div className="mb-4">
                <label className="input">
                  <PhoneInput
                    country={"us"}
                    enableSearch={true}
                    value={mobileno}
                    onChange={(event) => onChangeMobileNumber(event)}
                    inputProps={{
                      name: "mobile_no",
                      placeholder: " ",
                    }}
                  />
                  <span className="custom_label">
                    Phone Number<span className="text-danger">*</span>
                  </span>
                </label>
                {mobileNumberError && (
                  <p className="text-danger job_dis_form_label">
                    {mobileNumberError}
                  </p>
                )}
              </div>
            </div>

            <div className="col-12 col-md-6 px-2">
              <div className="mb-4">
                <label className="input">
                  <input
                    className="form-control job_dis_form_control rounded manual_profile_padding input__field"
                    placeholder={labels.CANDIDATE_EMAIL_LABEL}
                    type="text"
                    name="user_email"
                    value={email}
                    onInput={(val: any) => setEmail(val.target.value)}
                    data-validate-required="Please enter your email"
                    data-validate-email="email"
                    onFocus={(e) => hidePlaceHolder(e)}
                    onBlur={(e) =>
                      showPlaceHolder(e, labels.CANDIDATE_EMAIL_LABEL + " *")
                    }
                  />
                  <span className={`input__label`}>
                    {labels.CANDIDATE_EMAIL_LABEL}
                    <span
                      style={{
                        color: "red",
                        fontSize: "15px",
                        paddingLeft: "5px",
                      }}
                    >
                      *
                    </span>
                  </span>
                </label>
              </div>
            </div>

            <div className="col-12 col-md-6 px-2">
              <div className="mb-4">
                <label className="input">
                  <input
                    className="form-control job_dis_form_control rounded manual_profile_padding input__field"
                    placeholder={labels.CANDIDATE_LINKEDIN_LABEL}
                    type="text"
                    name="linkedin_url"
                    value={linkedinUrl}
                    onInput={(val: any) => setLinkedinUrl(val.target.value)}
                    //data-validate-required="Please enter linkedin address"
                    // data-validate-linkedin="linkedin"
                    onFocus={(e) => hidePlaceHolder(e)}
                    onBlur={(e) =>
                      showPlaceHolder(e, labels.CANDIDATE_LINKEDIN_LABEL)
                    }
                  />
                  <span className={`input__label`}>
                    {labels.CANDIDATE_LINKEDIN_LABEL}
                    {/* <span
                        style={{
                          color: "red",
                          fontSize: "15px",
                          paddingLeft: "5px",
                        }}
                      >
                        *
                      </span> */}
                  </span>
                </label>
              </div>
            </div>
            <div className="col-md-6 px-2">
              <label className="input">
                <input
                  type="date"
                  className="form-control job_dis_form_control px-3 rounded manual_profile_padding input__field"
                  min={moment().add(1, "day").format("YYYY-MM-DD")}
                  id="Availability"
                  name="availability_time"
                  placeholder="Availability"
                  value={availabilitytime}
                  onInput={(val: any) => setAvailabilitytime(val.target.value)}
                />

                <span className={`input__label input__label_disabled`}>
                  Availability To Join From
                </span>
              </label>
            </div>

            <div className="col-md-12 col-12 px-2">
              <div className="mb-4">
                <div className="row">
                  <div className="col-12">
                    <div className="row mt-2">
                      <div className="col-6 pe-2">
                        <label className="input">
                          <Creatable
                            isClearable={true}
                            isMulti={false}
                            className="form-control job_dis_form_control rounded input__field"
                            onChange={(e: any) => {
                              setCurrentSkill({
                                ...currentSkill,
                                skill: e,
                              });
                            }}
                            value={currentSkill.skill}
                            options={skills}
                          />
                          <span className={`input__label`}>
                            Skills
                            <span
                              style={{
                                color: "red",
                                fontSize: "15px",
                                paddingLeft: "5px",
                              }}
                            >
                              *
                            </span>
                          </span>
                        </label>
                      </div>
                      <div className="col-2 pe-3 ps-2">
                        <label className="input">
                          <select
                            className="form-control job_dis_form_control px-3 rounded manual_profile_padding input__field"
                            value={currentSkill.experience}
                            aria-label="Default select example"
                            onChange={(e) =>
                              setCurrentSkill({
                                ...currentSkill,
                                experience: e.target.value,
                              })
                            }
                          >
                            <option value="">Select</option>
                            {experienceList.map((data: any, index: number) => {
                              return (
                                <option key={index} value={data}>
                                  {data}
                                </option>
                              );
                            })}
                          </select>
                          <span className={`input__label`}>Experience</span>
                        </label>
                      </div>
                      <div className="col-2 pe-3">
                        <label className="input">
                          <select
                            className="form-control job_dis_form_control px-3 rounded manual_profile_padding input__field"
                            value={currentSkill.proficiency}
                            aria-label="Default select example"
                            onChange={(e) =>
                              setCurrentSkill({
                                ...currentSkill,
                                proficiency: e.target.value,
                              })
                            }
                          >
                            <option value="">Select</option>
                            <option value="Basic">Basic</option>
                            <option value="Advanced">Advanced</option>
                            <option value="Expert">Expert</option>
                          </select>
                          <span className={`input__label`}>Expertise</span>
                        </label>
                      </div>
                      {currentSkill.skill && (
                        <div className="col-2">
                          <img
                            src={ADD_ICON}
                            alt="add"
                            className="pointer ms-2 mt-2"
                            onClick={onAddSkills}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-12 px-2">
              <div className="mb-4">
                <div className="row">
                  <div className="col-12">
                    {mandatorySkills.map((data: any, index: number) => {
                      return (
                        <div className="row mt-2" key={index}>
                          <div className="col-6 pe-2">
                            <label className="input">
                              {/* <select
                                  className="form-control job_dis_form_control px-3 rounded manual_profile_padding input__field"
                                  aria-label="Default select example"
                                  onChange={(e) =>
                                    onChanegMandatorySkill(e, index)
                                  }
                                >
                                  <option value="">Select</option>
                                  {skills.map((data: any, index: number) => {
                                    return (
                                      <option key={index} value={data.skill}>
                                        {data.skill}
                                      </option>
                                    );
                                  })}
                                </select> */}
                              {/* {JSON.parse(data.toString()).toString()} */}
                              {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}

                              <Creatable
                                value={{ value: data.skill, label: data.skill }}
                                isMulti={false}
                                className="form-control job_dis_form_control rounded input__field"
                                onChange={(e) =>
                                  onChanegMandatorySkill(e, index)
                                }
                                options={skills}
                                isValidNewOption={(val: any) => false}
                              />
                              <span className={`input__label`}>
                                Skills
                                <span
                                  style={{
                                    color: "red",
                                    fontSize: "15px",
                                    paddingLeft: "5px",
                                  }}
                                >
                                  *
                                </span>
                              </span>
                            </label>
                          </div>
                          <div className="col-2 pe-3 ps-2">
                            <label className="input">
                              <select
                                className="form-control job_dis_form_control px-3 rounded manual_profile_padding input__field"
                                value={data?.experience}
                                aria-label="Default select example"
                                onChange={(e) =>
                                  onChanegMandatorySkillExperie(e, index)
                                }
                              >
                                <option value="">Select</option>
                                {experienceList.map(
                                  (data: any, index: number) => {
                                    return (
                                      <option key={index} value={data}>
                                        {data}
                                      </option>
                                    );
                                  }
                                )}
                              </select>
                              <span className={`input__label`}>
                                Experience
                                {/* <span
                                    style={{
                                      color: "red",
                                      fontSize: "15px",
                                      paddingLeft: "5px",
                                    }}
                                  >
                                    *
                                  </span> */}
                              </span>
                            </label>
                          </div>
                          <div className="col-2 pe-3">
                            <label className="input">
                              <select
                                className="form-control job_dis_form_control px-3 rounded manual_profile_padding input__field"
                                value={data?.proficiency}
                                aria-label="Default select example"
                                onChange={(e) => onSelectProficiency(e, index)}
                              >
                                <option value="">Select</option>
                                <option value="Basic">Basic</option>
                                <option value="Advanced">Advanced</option>
                                <option value="Expert">Expert</option>
                              </select>
                              <span className={`input__label`}>
                                Expertise
                                {/* <span
                                    style={{
                                      color: "red",
                                      fontSize: "15px",
                                      paddingLeft: "5px",
                                    }}
                                  >
                                    *
                                  </span> */}
                              </span>
                            </label>
                          </div>
                          <div className="col-2">
                            <img
                              src={DELETE_ICON}
                              alt="delete"
                              className="pointer ms-2 mt-2"
                              onClick={() => onDeleteSkill(index)}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {skillError && (
                  <p className="text-danger job_dis_form_label">{skillError}</p>
                )}
              </div>
            </div>

            <div className="col-md-12 col-12 px-2">
              <div className="mb-4">
                <div className="mb-5 mb-lg-4 mb-sm-4 mt-2">
                  <a
                    className="ps-3 pt-1 pb-1 pe-3  upload_cv position-relative"
                    target="_blank"
                  >
                    Upload CV
                    <input
                      id="upload_file1"
                      type="file"
                      name="cover_photo"
                      accept="application/pdf"
                      className={`upload_file_input_field `}
                      onChange={(e) => props.onUploadResume(e)}
                    />
                    <img src={UploadCV} className="ps-3 pb-1" alt="opencv" />
                  </a>
                </div>
                <div className="mb-4 mb-lg-0 mb-sm-4">
                  {props.resumeUrl && (
                    <a
                      href={`${CLOUDFRONT_URL}/${props.resumeUrl}`}
                      className="ps-3 pt-1 pb-1 pe-3 open_cv"
                      target="_blank"
                    >
                      Open CV{" "}
                      <img src={OpenCV} className="ps-4 pb-1" alt="opencv" />{" "}
                    </a>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-11 col-10 px-2">
                <div className="mb-4">
                  <ChipInput
                    type={"text"}
                    placeholder="Add Custom Tags *"
                    items={candidateFormData.value.tags}
                    isOutside={true}
                    outSideText={""}
                    getChipsFieldData={(data) => onChipData(data)}
                    isAdd={true}
                  ></ChipInput>
                </div>
              </div>
              <div className="col-2 col-md-1 text-end pe-0 pe-lg-3">
                <span
                  className=" sx-text-primary pointer  position-relative"
                  onClick={() => onShowAvailabilityNotification()}
                  onMouseLeave={() => setShowAvailabilityNotifications(false)}
                  onMouseEnter={onShowAvailabilityNotification}
                >
                  {" "}
                  <img
                    src={INFO_ICON}
                    alt="info icon"
                    className=" mt-lg-1 pt-1 mt-2 mobile_info "
                  />
                </span>
              </div>
              <div className="position-relative">
                {showAvailabilityNotifications && (
                  <div
                    onMouseEnter={onShowAvailabilityNotification}
                    className="rounded-3 availability_modal "
                    ref={notificationref}
                    onMouseLeave={() => setShowAvailabilityNotifications(false)}
                    style={{
                      zIndex: 999,
                      top: "-23px",
                    }}
                  >
                    <div className="row ">
                      <div className="col-md-12 top_para_styles mt-2 mb-2 pt-3 ps-4 px-lg-3">
                        <p className="availability_tooltip_fontsize">
                          {" "}
                          You can add custom tags to jobs to be able to identify
                          them easily and filter jobs based on the tags. E.g.
                          Particular team in a client company, Partner name who
                          referred the job etc.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </FormBuilder>
      <div className="text-end pb-4 px-2 d-flex justify-content-between">
        {/* <div className="position-absolute px-3 px-lg-5 bottom-30 bottom-sm-20 text-end"> */}
        {/* style={{ width: "100%", left: 0 }} */}
        <button
          className="large_btn_filter rounded me-3 ps-2 text-center"
          onClick={() => props.onClose()}
        >
          Cancel
        </button>
        <button
          className="large_btn_apply  rounded-3"
          onClick={() => createUpdateCandidate()}
        >
          Save
        </button>
        {formError && (
          <p className="text-danger job_dis_form_label">{formError}</p>
        )}
      </div>
    </>
  );
};

export default Addcandidate;
