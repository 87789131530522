import { forwardRef, SyntheticEvent, useEffect, useImperativeHandle, useState } from 'react';
import { useHistory } from 'react-router-dom';
import INFO_ICON from '../../assets/icon_images/info icon.svg';
import STAR_ICON from '../../assets/icon_images/star.svg';
import STAR_ICON_WITH_YELLOW from '../../assets/icon_images/star_yellow.svg';
import PROFILE_ICON from '../../assets/images/profile.png';
import { SmeService } from '../../app/service/sme.service';
import LOVE_BLACK_ICON from '../../assets/icon_images/candidate_heart_favorite_favorites_icon_black.svg';
import LOVE_YELLOW_ICON from '../../assets/icon_images/candidate_heart_favorite_favorites_icon_yellow.svg';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import { AppLoader } from '../loader';
import bomb from 'app/utility/bomb.js';
import { labels } from 'app/utility/constants';
import { CLOUDFRONT_URL } from 'config/constant';
import { SMEOverviewPopup } from './sme-overview-popup';

interface Props {
  jobId: string;
  onClose: () => void;
  slidersData: any;
  // onSave: (data: any) => void;
  isbutton: boolean; // Toggles to true when used in Job Creation Popup
}

export const SmesCards: any = forwardRef((props: Props, ref: any) => {
  const [searchStr, setSearchStr] = useState('');
  const [loading, setLoading] = useState(false);
  const [allSmeList, setAllSmeList] = useState<any[] | []>([]);
  const [apiSmeList, setApiSmeList] = useState<any[] | []>([]);
  const [searchKey, setSearchkey] = useState('');
  const [canLoadMore, setCanLoadMore] = useState(false);
  const companyId = sessionStorage.getItem('company_uuid') || '';
  const [smeSearchInput, setSmeSearchInput] = useState('');
  const [isCheckedRow, setIsCheckedRow] = useState(false);
  const [smeError, setSmeError] = useState<any>('');
  const history = useHistory();
  const [isFavouriteChecked, setIsFavouriteChecked] = useState(false);
  const onSearch = (search: string) => {};
  const onClose = () => {
    props.onClose();
  };
  const [matchedSmesCount, setMatchedSmesCount] = useState(0);
  const [showAvailabilityNotifications, setShowAvailabilityNotifications] = useState(false);
  const [isShowSMEOverviewPopup, setIsShowSMEOverviewPopup] = useState<any>(false);
  const [selectedSME, setSelectedSME] = useState({});

  useImperativeHandle(ref, () => ({
    onSliderUpdate(val: any) {
      setAllSmeList([
        ...apiSmeList.filter((sme: any) => {
          if (
            sme.sme_fee >= val.rateMinValue &&
            sme.sme_fee <= val.rateMaxValue &&
            sme.experience >= val.experienceMinValue &&
            sme.experience <= val.experienceMaxValue
          ) {
            if (isFavouriteChecked) {
              if (sme.isFav == true) return sme;
            } else return sme;
          }
        }),
      ]);
    },
  }));

  useEffect(() => {
    if (props.isbutton === false) {
      loadRecommendedSMES();
    } else {
      getSelectedSMEList();
    }
  }, []);

  const manageFavorite = (smeUuid: any, isFavourite: boolean) => {
    setLoading(true);
    const data = {
      sme_uuid: smeUuid,
      company_uuid: companyId,
    };

    if (!isFavourite) {
      SmeService.smeBookMark(data).then((res) => {
        if (res.error) {
          setLoading(false);
          toast.error(res?.error?.message);
        } else {
          setLoading(false);
          allSmeList.map((sme: any) => {
            if (sme.uuid == smeUuid) {
              sme.isFav = true;
            }
          });
          setAllSmeList([...allSmeList]);
        }
      });
    } else {
      SmeService.smeRemoveFavourite(data).then((res) => {
        if (res.error) {
          setLoading(false);
          toast.error(res?.error?.message);
        } else {
          setLoading(false);
          allSmeList.map((sme: any) => {
            if (sme.uuid == smeUuid) {
              sme.isFav = false;
            }
          });
          setAllSmeList([...allSmeList]);
        }
      });
    }
  };

  const onSearchSmeAll = (e: SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    const value = target.value;
    setSmeSearchInput(value);
    if (value === '') {
      setAllSmeList([...apiSmeList]);
    } else {
      const smeNames = apiSmeList.filter((el: any) => {
        return (
          el.user_firstname.toLowerCase().indexOf(value.toLocaleLowerCase()) > -1 ||
          el.user_lastname.toLowerCase().indexOf(value.toLocaleLowerCase()) > -1
        );
      });
      setAllSmeList(smeNames);
    }
  };

  const getSelectedSMEList = () => {
    setLoading(true);
    const data = {
      job_uuid: props.jobId,
    };

    SmeService.seletedSMEsByJobId(data).then((res) => {
      if (res.error) {
        setLoading(false);
        toast.error(res?.error?.message);
      } else {
        if (res?.smes) {
          setApiSmeList([...apiSmeList, ...res.smes]);
          setAllSmeList([...apiSmeList, ...res.smes]);
          setMatchedSmesCount(res.smes.length);
          setLoading(false);
        }
      }
    });
  };

  const loadRecommendedSMES = () => {
    setLoading(true);
    let existingSmes: any = [];
    apiSmeList.forEach((sme: any) => {
      existingSmes.push(sme.uuid);
    });
    const data = {
      job_uuid: props.jobId,
      search_key: searchKey,
      existing_smes: existingSmes,
    };
    SmeService.recommendedSmes(data).then((res) => {
      if (res.error) {
        setLoading(false);
        toast.error(res?.error?.message);
      } else {
        if (res?.smes) {
          // Calling this API to find the user favourite SMEs
          SmeService.getUserFavSmes().then((favSmes) => {
            if (favSmes.error) {
              setLoading(false);
              toast.error(favSmes?.error?.message);
            } else {
              res?.smes?.forEach((element: any) => {
                element.experience = 0;
                element.matchedSkills.forEach((skill: any) => {
                  if (skill.experience > element.experience) element.experience = skill.experience;
                });
                const fav: any = favSmes.findIndex((data: any) => data?.user_uuid === element.uuid);
                element.isFav = fav == -1 ? false : true;
              });
              setApiSmeList([...apiSmeList, ...res.smes]);
              setAllSmeList([...apiSmeList, ...res.smes]);
            }
          });
        }
        setMatchedSmesCount(res.matched_smes_count);
        setSearchkey(res.search_key);
        setCanLoadMore(res.can_load_more);
        setLoading(false);
      }
    });
  };

  const onSeeUserFav = (event: any) => {
    setIsFavouriteChecked(event.target.checked);
    if (event.target.checked) {
      const favs = allSmeList.filter((sme: any) => {
        if (sme.isFav) {
          return sme;
        }
      });
      setAllSmeList(favs);
    } else {
      setAllSmeList(apiSmeList);
    }
  };

  const selectSmesList = (e: any, index: any) => {
    allSmeList[index].checked = e.target.checked;
    setAllSmeList([...allSmeList]);
    isChecked(allSmeList);
  };

  const isChecked = (smesListData: any) => {
    const selectedData = smesListData.find((data: any) => {
      return !data.checked;
    });

    if (!selectedData) {
      setIsCheckedRow(true);
    } else {
      setIsCheckedRow(false);
    }
  };

  const onSelectAll = (event: any) => {
    allSmeList.map((data: any, index: number) => {
      {
        console.log('data', data);
      }
      data.checked = event.target.checked;
    });
    isChecked(allSmeList);
    setAllSmeList([...allSmeList]);
  };

  const submit = () => {
    const selectedSmes: any = [];
    allSmeList.forEach((element: any) => {
      if (element.checked) {
        selectedSmes.push(element.uuid);
      }
    });
    const data = {
      job_uuid: props.jobId,
      smes: selectedSmes,
    };
    setLoading(true);
    SmeService.scheduleInterviews(data).then((res) => {
      setLoading(false);
      if (res.error) {
        toast.error(res?.error?.message);
      } else if (res.message) {
        setSmeError(res.message.message);
      } else if (res.length === 0) setSmeError('Something went wrong. Please try again later');
      else if (res.length > 0) {
        toast.success('Submitted request to SMEs successfully');
        history.push(`/dashboard/companies/info/${companyId}/jobs/info/${props.jobId}/interviews`);
        if (!props.isbutton) bomb.continuous();
      }
    });
    setSmeError('');
  };

  const onShowAvailabilityNotification = () => {
    setShowAvailabilityNotifications(!showAvailabilityNotifications);
  };

  return (
    <>
      {loading && <AppLoader loading={loading}></AppLoader>}
      <ReactTooltip
        place='bottom'
        type='light'
        effect='solid'
        border={true}
        borderColor={'#707070'}
      />
      <div className='col-12 mt-1 mb-2'>
        <div className='input-group candidate_search_bar_border ms-3'>
          <input
            type='text'
            className='form-control form_control_border py-md-2'
            placeholder='Search SME By Name'
            aria-label='Username'
            aria-describedby='basic-addon1'
            // onKeyPress={(e) => onSearchText(e)}
            // onInput={(e) => onSearchTextEmpty(e)}
            value={smeSearchInput}
            onChange={onSearchSmeAll}
          />
          <span className='input-group-text input_group_text' id='basic-addon1'>
            <i
              className='fa fa-search pointer'
              aria-hidden='true'
              onClick={() => onSearch(searchStr)}
            ></i>
          </span>
        </div>
      </div>

      <div className='mt-4 mx-3'>
        {matchedSmesCount === 1 && props.isbutton === false && (
          <div>{matchedSmesCount} SME matched for this job</div>
        )}

        {matchedSmesCount > 1 && props.isbutton === false && (
          <div>{matchedSmesCount} SMEs matched for this job</div>
        )}

        {matchedSmesCount === 1 && props.isbutton === true && (
          <div>{matchedSmesCount} SME selected for this job</div>
        )}

        {matchedSmesCount > 1 && props.isbutton === true && (
          <div>{matchedSmesCount} SMEs selected for this job</div>
        )}
      </div>

      <div className='d-flex my-4'>
        {props.isbutton === false && (
          <div className='d-flex mx-3'>
            <input
              className='form-check-input'
              type='checkbox'
              id='flexCheckDefault'
              checked={isCheckedRow}
              onChange={(e) => onSelectAll(e)}
            />
            <label className='form-check-label text-black fs_14 ms-2'>
              Select All({allSmeList.length})
            </label>
            <span className='position-relative' data-tip={labels.SME_SELECT_ALL_TOOL_TIP}>
              <img
                src={INFO_ICON}
                alt='info icon'
                className='ms-2 sx-text-primary pointer mb-1 mobile_info'
                onClick={() => onShowAvailabilityNotification()}
                onMouseLeave={() => setShowAvailabilityNotifications(false)}
                onMouseEnter={onShowAvailabilityNotification}
              />
            </span>
          </div>
        )}

        <div className='d-flex ms-4'>
          <input
            className='form-check-input'
            type='checkbox'
            id='flexCheckDefault'
            onClick={(e: any) => onSeeUserFav(e)}
          />
          <label className='form-check-label text-black fs_14 ms-2'>
            My Favorites
            <span className='position-relative' data-tip={labels.SME_MY_FAVOURITES_TOOL_TIP}>
              <img
                src={INFO_ICON}
                alt='info icon'
                className='ms-2 sx-text-primary pointer mb-1 mobile_info'
              />
            </span>
          </label>
        </div>
      </div>

      <div className='mx-3'>
        {allSmeList.length < 1 && !canLoadMore && <p className='fs_14'>SMEs not found</p>}
      </div>

      <div className='col-12'>
        <div className='row'>
          {allSmeList.map((data: any, index: any) => {
            return (
              <>
                <div className='col-lg-4'>
                  <div
                    className='p-3 ms-3 mb-3 mt-1 rounded-3'
                    style={{
                      minHeight: '146px',
                      backgroundColor: ' #F5F5F5',
                    }}
                  >
                    <div className='row'>
                      <div className='col-5'>
                        {props.isbutton === false && (
                          <input
                            className='form-check-input m-0'
                            type='checkbox'
                            id='flexCheckDefault'
                            checked={data?.checked}
                            onChange={(e) => selectSmesList(e, index)}
                          />
                        )}

                        <img
                          onClick={() => {
                            setSelectedSME(data);
                            setIsShowSMEOverviewPopup(Date.now());
                          }}
                          className='fs_14 pointer mt-1'
                          style={{
                            height: '82px',
                            width: '82px',
                            borderRadius: '50%',
                          }}
                          src={
                            data.user_image && data.user_image.indexOf('https') > -1
                              ? data.user_image
                              : data.user_image
                              ? `${CLOUDFRONT_URL}/${data.user_image}`
                              : PROFILE_ICON
                          }
                          alt='SME Profile Picture'
                        />
                      </div>
                      <div className='col-7 '>
                        <div className='d-flex justify-content-between'>
                          <div
                            className='side_heading pointer'
                            onClick={() => {
                              setSelectedSME(data);
                              setIsShowSMEOverviewPopup(Date.now());
                            }}
                          >
                            {data.user_firstname} {data.user_lastname}
                          </div>
                          {props.isbutton === false && (
                            <div className='position-relative' style={{ top: '-9px' }}>
                              {data.isFav ? (
                                <img
                                  src={LOVE_YELLOW_ICON}
                                  className='pointer'
                                  onClick={() => manageFavorite(data.uuid, data.isFav)}
                                />
                              ) : (
                                <img
                                  src={LOVE_BLACK_ICON}
                                  className='pointer'
                                  onClick={() => manageFavorite(data.uuid, data.isFav)}
                                />
                              )}
                            </div>
                          )}
                          {props.isbutton === true && (
                            <div className='position-relative' style={{ top: '-9px' }}>
                              {data.isFav ? (
                                <img src={LOVE_YELLOW_ICON} />
                              ) : (
                                <img src={LOVE_BLACK_ICON} />
                              )}
                            </div>
                          )}
                        </div>
                        <div className='side_heading'>${data.sme_fee} Per Interview</div>
                        {/* <div className='me-2' style={{ fontSize: '15px' }}>
                          4.3
                          <span>
                            <img src={STAR_ICON_WITH_YELLOW} alt='' />
                          </span>
                          <span>
                            <img src={STAR_ICON_WITH_YELLOW} alt='' />
                          </span>
                          <span>
                            <img src={STAR_ICON_WITH_YELLOW} alt='' />
                          </span>
                          <span>
                            <img src={STAR_ICON_WITH_YELLOW} alt='' />
                          </span>
                          <span>
                            <img src={STAR_ICON} alt='' />
                          </span>
                        </div> */}
                        <div>
                          {data?.matchedSkills?.length > 0 ? (
                            data?.matchedSkills?.map((data: any, index: number) => {
                              return (
                                <>
                                  {' '}
                                  {index < 2 && (
                                    <span
                                      className='sx-bg-color text-black  py-1 px-2 d-inline-block'
                                      key={index}
                                      style={{
                                        fontSize: '8px',
                                        borderRadius: '10px',
                                      }}
                                    >
                                      {data?.skill}
                                    </span>
                                  )}
                                </>
                              );
                            })
                          ) : (
                            <span className='top_para_styles' style={{ fontSize: '8px' }}>
                              No skills
                            </span>
                          )}
                          {data?.matchedSkills?.length > 2 ? (
                            <span className='ms-2 d-inline-block' style={{ fontSize: '8px' }}>
                              +3 More
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>

        {/* Displays in Job Creation popup */}
        <div className='text-center'>
          {props.isbutton === false && canLoadMore && (
            <button className='large_btn rounded ms-3 my-2' onClick={loadRecommendedSMES}>
              Load More
            </button>
          )}
        </div>
      </div>

      <SMEOverviewPopup isShowPopup={isShowSMEOverviewPopup} smeData={selectedSME} />
      {/* Displays submit request button in Job Creation popup */}
      {props.isbutton === false && (
        <div
          className={`row position-absolute px-3 px-lg-5 bottom-30 bottom-sm-20`}
          style={{ width: '100%', left: 0 }}
        >
          <div className='col-md-6 col-6  mt-5 mt-lg-0 mt-sm-4'>
            <button className='btn-signup rounded ms-3' type='button' onClick={onClose}>
              Previous
            </button>
          </div>
          <div className='col-md-6 col-6 text-end mt-5 mt-lg-0 mt-sm-4 pe-2'>
            <button className='large_btn_apply rounded me-4' type='button' onClick={submit}>
              Submit Request
            </button>
            {smeError && <p className='text-danger top_para_styles'>{smeError}</p>}
          </div>
        </div>
      )}
    </>
  );
});
