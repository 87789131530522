import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import PhoneInput from 'react-phone-input-2';
import TimezoneSelect from 'react-timezone-select';
import { UsersService } from '../../app/service/users.service';
import {
  allCountryMobileNumberValidations,
  emialValidations,
  linkedinValidations,
  nameValidations,
} from '../../app/utility/form-validations';
import { CLOUDFRONT_URL, linkedInClientId } from '../../config/constant';
import FormBuilder from '../form-builder';
import CAMERA_ICON from './../../assets/icon_images/camara_icon.svg';
import Profile from './../../assets/images/profile.png';
import UploadCV from './../../assets/icon_images/Upload CV.png';
import OpenCV from './../../assets/icon_images/Open CV.png';
import INFO_ICON from '../../assets/icon_images/info icon.svg';
import { Modal } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import videoIcon from '../../assets/icon_images/Video Icon.png';
import { S3Helper } from '../../app/utility/s3-helper';
import { toast } from 'react-toastify';
import UPLOAD_ICON from './../../assets/icon_images/Upload CV.svg';
import Delete from '../../assets/icon_images/delete.svg';

interface Props {
  countryesData: any[];
  profilePicUrl: string;
  onUploadProfilePic: (e: any) => void;
  onUploadResume: (e: any) => void;
  resumeUrl: string;
  onSave: (data: any, resetAvailability?: number) => void;
  currentSmeData: any;
  onProfilePicChanged: (url: string) => void;
  editTimeZone: boolean;
  enableTimezoneWarning: boolean;
}

export const AddUpdatePersonalInfo: React.FC<Props> = (props: Props) => {
  const [smeFormData, setSmeFormData] = useState<any>(props?.currentSmeData);
  const [firstNameError, setFirstNameError] = useState<any>('');
  const [lastNameError, setLastNameError] = useState<any>('');
  const [countryError, setCountryError] = useState<any>('Please select the country');
  const [timeZoneError, setTimeZoneError] = useState<any>('Please select the time zone');
  const [linkedInURLError, setLinkedInURLError] = useState<any>(
    'Please enter the link for your linkedIn profile'
  );
  const [phoneNumberError, setPhoneNumberError] = useState<any>('');
  const [currentTitleError, setCurrentTitleError] = useState<any>('Please enter your expert title');
  const [currentcompanyError, setCurrentcompanyError] = useState<any>('');
  const [emailError, setEmailError] = useState('');
  const [selectedCountry, setSelectedCountry] = useState<any>('');
  const [selectedTimeZone, setSelectedTimeZone] = useState<any>({});
  const profilePicFileInput = useRef(null);
  // const [ResumeError, setResumeError] = useState<any>('Please upload your latest resume');
  const [formError, setFormError] = useState<boolean>(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [showAvailabilityNotifications, setShowAvailabilityNotifications] = useState(false);
  const notificationref = useRef<any>(null);
  const [canshowTimzonePopup, setCanshowTimzonePopup] = useState(false);
  const [videoUrl, setVideoUrl] = useState<any>(props?.currentSmeData?.introduction_video_url);
  const [videoUrlError, setVideoUrlError] = useState<any>('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.currentSmeData?.user_email) {
      let data = props.countryesData.find(
        (element: any) => element.code === props.currentSmeData.country_code
      );
      setSelectedTimeZone(props.currentSmeData.time_zone || '');
      if (data) {
        setSelectedCountry({ value: data.code, label: data.name });
      }
      setSmeFormData({ ...props.currentSmeData });
    }
  }, [props.currentSmeData]);

  useEffect(() => {
    if (props.resumeUrl) {
      setSmeFormData({ ...smeFormData, resume_url: props.resumeUrl });
    }
  }, [props.resumeUrl]);

  const handleInput = (data: any) => {
    data.value = { ...smeFormData, ...data?.value };
    setSmeFormData({ ...data.value });
    setFormError(false);
  };

  const getUserCredentials = (code: any) => {
    UsersService.getUserCredentials(code).then((data) => {
      const linkedIn: any = {
        user_firstname: data?.firstName,
        user_lastname: data?.lastName,
        user_image: data?.profileImageURL,
      };
      //   const linkedIn: any = {
      //     user_firstname: 'my firstName',
      //     user_lastname: 'my last name',
      //     user_image: 'dataprofileImageURL',
      //   };

      const preparedData = { ...smeFormData, ...linkedIn };
      if (data?.profileImageURL) {
        props.onProfilePicChanged(data.profileImageURL);
      }
      setFirstNameError(false);
      setLastNameError(false);
      setSmeFormData(preparedData);
    });
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: linkedInClientId,
    redirectUri: `${window.location.origin}/linkedin`,
    onSuccess: (code) => {
      getUserCredentials(code);
    },
    scope: 'r_emailaddress r_liteprofile',
    onError: (error) => {},
  });

  const onMobileNumber = (event: any) => {
    setPhoneNumberError('');
    setSmeFormData({ ...smeFormData, mobile_no: event });
    if (!event) {
      setPhoneNumberError('Please enter your phone number');
    }
  };

  const onChangeFirstName = (event: any) => {
    setFirstNameError(nameValidations(event, 'first name'));
    if (event.user_firstname.length > 15) {
      setFirstNameError('Maximum length allowed is 15 characters');
    }
    setSmeFormData({ ...smeFormData, user_firstname: event });
  };

  const onChangeLastName = (event: any) => {
    setLastNameError(nameValidations(event, 'last name'));
    if (event.user_lastName.length > 15) {
      setFirstNameError('Maximum length allowed is 15 characters');
    }
    setSmeFormData({ ...smeFormData, user_lastname: event });
  };

  // const onSelectCountry = (event: any) => {
  //     setCountryError('');
  //     setSelectedCountry(event.target.value);
  //     const smeData = smeFormData
  //     smeData.country_code = event.target.value;
  //     setSmeFormData({ ...smeData });
  //     if (!event.target.value) {
  //         setCountryError('Please select the country');
  //     }
  // }

  const onSelectTimeZone = (event: any) => {
    setTimeZoneError('');
    if (!event) {
      setTimeZoneError('Please select the time zone');
    } else {
      setSelectedTimeZone(event);
    }
    const smeData = smeFormData;
    smeData.time_zone = event.value;
    setSmeFormData({ ...smeData });
  };

  const onLinkedIn = (event: any) => {
    setLinkedInURLError(linkedinValidations(event.target.value, 'linkedIn'));
    const smeData = smeFormData;
    smeData.linkedin_url = event.target.value;
    setSmeFormData({ ...smeData });
  };

  const onExpertTitle = (event: any) => {
    setCurrentTitleError(nameValidations(event.target.value, 'expert title'));
    const smeData = smeFormData;
    smeData.expert_title = event.target.value;
    setSmeFormData({ ...smeData });
  };
  const preventDefaultActions = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const clickProfilepicInput = () => {
    const target: any = profilePicFileInput.current;
    if (target) {
      target.click();
    }
  };

  const onShowAvailabilityNotification = () => {
    setShowAvailabilityNotifications(true);
    // setShowNotifications(true);
    // setShowProfile(false);
  };

  const onClickSave = () => {
    setIsSubmitClicked(true);
    if (
      smeFormData?.user_firstname &&
      smeFormData?.user_lastname &&
      smeFormData?.country_code &&
      smeFormData?.linkedin_url &&
      smeFormData?.expert_title &&
      smeFormData?.mobile_no &&
      !firstNameError &&
      !lastNameError
    ) {
      if (smeFormData.time_zone !== props.currentSmeData.time_zone && props.enableTimezoneWarning) {
        setCanshowTimzonePopup(true);
      } else {
        props.onSave(smeFormData);
      }
    } else {
      setFormError(true);
    }
  };

  const onSelectCountry = (selectedList: any) => {
    setCountryError('');
    setSelectedCountry(selectedList);
    const smeData = smeFormData;
    smeData.country_code = selectedList.code;
    setSmeFormData({ ...smeData });
    if (!selectedList.code) {
      setCountryError('Please select the country');
    }
  };

  const goToAvailability = (resetStep: number): void => {
    props.onSave(smeFormData, resetStep);
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const isClearable = () => {
    setSelectedCountry('');
  };

  const onUploadVideo = async (event: any) => {
    setLoading(true);
    setVideoUrl('');
    setVideoUrlError('');
    if (event.target.files && event.target.files[0]) {
      let fileSize = event.target.files[0].size;
      fileSize = Math.round(fileSize / 1048576); // Formula to Convert file size from bytes to MB
      if (fileSize <= 100) {
        const data = {
          type: 'mp4',
        };
        UsersService.profileVideoUrl(data).then(async (res) => {
          if (res?.error) {
            setLoading(false);
            toast.error(res?.error?.message);
          } else {
            const result = await S3Helper.uploadFilesToS3BySigned(
              res.presignedUrl,
              event.target.files[0],
              event.target.files[0]?.type
            );
            smeFormData.introduction_video_url = res.fileUrl;
            setSmeFormData({ ...smeFormData });
            setVideoUrl(`${res.fileUrl}`);
            setLoading(false);
            toast.success('Uploaded Successfully');
          }
        });
      } else {
        setLoading(false);
        setVideoUrlError('Video size must be less than 100 MB');
      }
    }
  };

  return (
    <>
      <ReactTooltip
        place='bottom'
        type='light'
        effect='solid'
        border={true}
        borderColor={'#707070'}
      />
      <div className=''>
        <h6 className='top_heading_styles'>Tell us about yourself</h6>
        <div>
          <button onClick={linkedInLogin} className='connect_linkedin_btn_profile me-3'>
            Fetch Information from LinkedIn
          </button>
          <span
            data-tip='We will fetch first name, last name and profile picture.'
            className=' h-100 sx-text-primary pointer ps-0 position-relative'
          >
            <img src={INFO_ICON} alt='info icon' className='ps-1 mobile_info' />
          </span>
        </div>
      </div>

      <FormBuilder onUpdate={handleInput}>
        <form
          className={`form-read-only row flex-column-reverse flex-lg-row`}
          onSubmit={preventDefaultActions}
        >
          <div className='col-lg-9 mt-4'>
            <div className='row me-lg-5'>
              <div className='col-lg-6 col-12 mb_22 pe-lg-5'>
                <label className='input'>
                  <input
                    type='text'
                    className='form-control job_dis_form_control px-3 rounded manual_profile_padding input__field'
                    id='smeFirstName'
                    name='user_firstname'
                    placeholder='First Name *'
                    value={smeFormData?.user_firstname}
                    onInput={(e: any) => onChangeFirstName(e.target.value)}
                  />
                  <span className={`input__label input__label_disabled`}>
                    First Name<span className='text-danger'>*</span>
                  </span>
                </label>
                {isSubmitClicked && firstNameError && (
                  <small className='text-danger job_dis_form_label'>{firstNameError}</small>
                )}
              </div>
              <div className='col-lg-6 col-12 mb_22 ps-lg-3'>
                <label className='input'>
                  <input
                    type='text'
                    className='form-control job_dis_form_control px-3 rounded manual_profile_padding input__field'
                    id='smeLastName'
                    name='user_lastname'
                    placeholder='Last Name *'
                    value={smeFormData?.user_lastname}
                    onInput={(e: any) => onChangeLastName(e.target.value)}
                  />
                  <span className={`input__label input__label_disabled`}>
                    Last Name<span className='text-danger'>*</span>
                  </span>
                </label>
                {isSubmitClicked && lastNameError && (
                  <small className='text-danger job_dis_form_label'>{lastNameError}</small>
                )}
              </div>
              <div className='col-lg-6 mb_22 pe-lg-5'>
                <div className='row'>
                  <label className='input'>
                    <PhoneInput
                      country={'us'}
                      enableSearch={true}
                      value={smeFormData?.mobile_no}
                      onChange={(phone: any) => onMobileNumber(phone)}
                      inputProps={{
                        name: 'mobile_no',
                        placeholder: ' ',
                      }}
                    />
                    <span className='custom_label'>
                      Phone Number<span className='text-danger'>*</span>
                    </span>
                  </label>
                  {/* {isSubmitClicked && (!smeFormData.mobile_no || phoneNumberError) && <small className="text-danger job_dis_form_label">{phoneNumberError || 'Please enter your phone number'}</small>} */}
                  {isSubmitClicked && (!smeFormData.mobile_no || phoneNumberError) && (
                    <small className='text-danger job_dis_form_label'>{phoneNumberError}</small>
                  )}
                </div>
              </div>

              <div className='col-lg-6 col-12 mb_22 ps-lg-3'>
                <label className='input'>
                  <Select
                    value={selectedCountry}
                    placeholder='Select Country'
                    onChange={(e) => onSelectCountry(e)}
                    options={props.countryesData}
                    className='input__field'
                    isClearable={false}
                  />
                  <span className={`input__label input__label_disabled`}>
                    Country<span className='text-danger'>*</span>
                  </span>
                </label>
                {isSubmitClicked && !smeFormData.country_code && (
                  <small className='text-danger job_dis_form_label'>{countryError}</small>
                )}
              </div>
              <div className='col-lg-6 col-12 mb_22 pe-lg-5'>
                <label className='input'>
                  <input
                    type='text'
                    className='form-control job_dis_form_control px-3 rounded input__field'
                    id='WorkEmail'
                    placeholder='Enter email *'
                    value={smeFormData?.user_email}
                    disabled
                  />
                  <span className={`input__label input__label_disabled`}>
                    Email<span className='text-danger'>*</span>
                  </span>
                </label>
                {isSubmitClicked && (!smeFormData.user_email || emailError) && (
                  <small className='text-danger job_dis_form_label'>{emailError}</small>
                )}
              </div>

              <div className='col-lg-6 col-12 mb_22 ps-lg-3'>
                <label className='input disabled-input'>
                  <TimezoneSelect
                    value={selectedTimeZone}
                    onChange={(e) => onSelectTimeZone(e)}
                    // isDisabled={props.editTimeZone}
                    className='input__field'
                  />
                  <span className={`input__label input__label_disabled`}>
                    Time Zone<span className='text-danger'>*</span>
                  </span>
                </label>
                {isSubmitClicked && !smeFormData.time_zone && (
                  <small className='text-danger job_dis_form_label'>{timeZoneError}</small>
                )}
              </div>
              <div className='col-lg-6 col-12 mb_22 pe-lg-5'>
                <label className='input'>
                  <input
                    type='text'
                    className='form-control job_dis_form_control px-3 rounded manual_profile_padding input__field'
                    id='linkedin_url'
                    name='linkedin_url'
                    placeholder='LinkedIn URL *'
                    value={smeFormData?.linkedin_url}
                    onInput={(e) => onLinkedIn(e)}
                  />
                  <span className={`input__label input__label_disabled`}>
                    LinkedIn URL<span className='text-danger'>*</span>
                  </span>
                </label>
                {isSubmitClicked && !smeFormData.linkedin_url && (
                  <small className='text-danger job_dis_form_label'>{linkedInURLError}</small>
                )}

                {/* {isSubmitClicked && (!smeFormData.linkedin_url || linkedInURLError) && <small className="text-danger job_dis_form_label">{linkedInURLError || 'Enter linkedin url'}</small>} */}
              </div>
              <div className='col-12 col-lg-6 ps-lg-3 mb_22'>
                <label className='input'>
                  <input
                    type='text'
                    className='form-control job_dis_form_control px-3 rounded manual_profile_padding input__field'
                    id='expert_title'
                    name='expert_title'
                    placeholder='Current title *'
                    value={smeFormData?.expert_title}
                    onInput={(e) => onExpertTitle(e)}
                  />
                  <span className={`input__label input__label_disabled`}>
                    Current Title<span className='text-danger'>*</span>
                  </span>
                </label>
                {isSubmitClicked && !smeFormData.expert_title && (
                  <small className='text-danger job_dis_form_label'>{currentTitleError}</small>
                )}

                {/* {isSubmitClicked && (!smeFormData.expert_title || currentTitleError) && <small className="text-danger job_dis_form_label">{currentTitleError || 'Please enter expert title'}</small>} */}
              </div>
              <div className='col-12 mb_22'>
                <label className='input'>
                  <input
                    type='text'
                    className='form-control job_dis_form_control px-3 rounded manual_profile_padding input__field'
                    id='current_company'
                    name='current_company'
                    placeholder='Company name'
                    value={smeFormData?.current_company}
                  />
                  <span className={`input__label input__label_disabled`}>Current company</span>
                </label>
                {currentcompanyError && (
                  <small className='text-danger job_dis_form_label'>{currentcompanyError}</small>
                )}
              </div>
            </div>
            {/*  */}
            <div className='col-12 mb-3'>
              <label className='form-label job_dis_form_label top_heading_styles fw_6'>
                Upload an Introduction Video
              </label>
              <div className='mb-2'>
                <div className='m-0' style={{ fontSize: '12px' }}>
                  Video should cover details like name, technology experience, expertise, passion
                  and past experience around
                </div>
                <div style={{ fontSize: '12px' }}>
                  interviewing and hiring technology resources. You can also mention what is unique
                  about yourself, i.e. your USP
                </div>
              </div>
              <div
                className={`profile_picture_main_div_video text-center position-relative m-0 ${
                  videoUrl && 'p-0'
                }`}
              >
                {videoUrl ? (
                  <video
                    src={`${CLOUDFRONT_URL}/${videoUrl}`}
                    width='300'
                    height='240'
                    controls
                  ></video>
                ) : (
                  <div>
                    {' '}
                    <input
                      id='upload_file1'
                      type='file'
                      name='cover_photo'
                      className={`upload_file_input_field`}
                      accept='video/mp4'
                      onChange={(e) => onUploadVideo(e)}
                    />
                    <div className='row'>
                      <div className='col-md-6 col-6 text-start'>
                        <ul className='list-inline mt-4 mt-sm-4 mt-lg-4'>
                          <li className='upload_img' itemType='file' style={{ color: '#1E1E1E' }}>
                            Upload a file from your device
                          </li>
                          <li className='upload_img'>
                            <small>Supported formats : mov, mp4</small>
                          </li>
                          <li className='upload_img'>
                            <small>maximum size : 100mb</small>
                          </li>
                          <li className='upload_img'>
                            <small>maximum duration : 1minute</small>
                          </li>
                        </ul>
                      </div>
                      <div className='col-md-6 col-6 text-end pe-5 mt-5'>
                        <img src={videoIcon} alt='videoIcon' />
                        {/* <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M4.66683 9H0.333496V39.3333C0.333496 41.7167 2.2835 43.6667 4.66683 43.6667H35.0002V39.3333H4.66683V9ZM39.3335 0.333336H13.3335C10.9502 0.333336 9.00016 2.28334 9.00016 4.66667V30.6667C9.00016 33.05 10.9502 35 13.3335 35H39.3335C41.7168 35 43.6668 33.05 43.6668 30.6667V4.66667C43.6668 2.28334 41.7168 0.333336 39.3335 0.333336ZM22.0002 27.4167V7.91667L35.0002 17.6667L22.0002 27.4167Z" fill="#777F8A" />
                                                                    </svg> */}
                      </div>
                    </div>
                  </div>
                )}
                {videoUrl && (
                  <div className='position-absolute d-flex top-0 end-0 me-2 mt-2'>
                    <img
                      data-tip='Remove video'
                      src={Delete}
                      alt='Delete'
                      className='pointer'
                      onClick={() => {
                        setVideoUrl('');
                        smeFormData.introduction_video_url = '';
                        setSmeFormData({ ...smeFormData });
                      }}
                    />
                    <label htmlFor='upload_file1_videoupload' className='ms-2'>
                      <img
                        data-tip='Upload new video'
                        src={UPLOAD_ICON}
                        alt='Delete'
                        className='pointer sx-text-primary'
                      />
                      <input
                        // disabled={isRateFormEdit}
                        id='upload_file1_videoupload'
                        type='file'
                        name='cover_photo'
                        className='d-none'
                        accept='video/mp4'
                        onChange={(e) => onUploadVideo(e)}
                      />
                    </label>
                  </div>
                )}
              </div>
              {videoUrlError && <p className='text-danger job_dis_form_label'>{videoUrlError}</p>}
            </div>
            {/*  */}
          </div>

          <div className='col-md-3 text-lg-center'>
            <div className='ms-lg-5 mt-4'>
              <div className='profile_picture_main_div d-lg-flex justify-content-lg-center w-100'>
                <div
                  className='d-flex align-items-end justify-content-center'
                  style={{
                    backgroundImage: `url(${
                      props.profilePicUrl
                        ? props.profilePicUrl.includes('http')
                          ? props.profilePicUrl
                          : `${CLOUDFRONT_URL}/${props.profilePicUrl}`
                        : Profile
                    })`,
                    width: `160px`,
                    height: `160px`,
                    backgroundSize: 'cover',
                    borderRadius: '50%',
                  }}
                >
                  <img
                    src={CAMERA_ICON}
                    alt='profile'
                    className='pointer pb-1'
                    title='Upload New Image'
                    onClick={clickProfilepicInput}
                  />
                  <input
                    ref={profilePicFileInput}
                    type='file'
                    name='myfile'
                    accept='image/png, image/jpeg'
                    className='d-none'
                    onChange={(e) => props.onUploadProfilePic(e)}
                  />
                </div>
                <div>
                  {/* <i className="bi bi-camera bi_camera_position">
                            </i> */}
                </div>
              </div>
              <div className='col-12 mt-5'>
                <div className='mb-5 mb-lg-4 mb-sm-4'>
                  <a className='ps-3 pt-1 pb-1 pe-3  upload_cv position-relative' target='_blank'>
                    Upload CV
                    <input
                      id='upload_file1'
                      type='file'
                      name='cover_photo'
                      accept='application/pdf'
                      className={`upload_file_input_field `}
                      onChange={(e) => props.onUploadResume(e)}
                    />
                    <img src={UploadCV} className='ps-3 pb-1' alt='opencv' />
                  </a>
                  {/* {isSubmitClicked && !props.resumeUrl && <div><small className="text-danger job_dis_form_label">{ResumeError}</small></div>} */}
                </div>
                <div className='mb-4 mb-lg-0 mb-sm-4'>
                  {props.resumeUrl && (
                    <a
                      href={`${CLOUDFRONT_URL}/${props.resumeUrl}`}
                      className='ps-3 pt-1 pb-1 pe-3 open_cv'
                      target='_blank'
                    >
                      Open CV <img src={OpenCV} className='ps-4 pb-1' alt='opencv' />{' '}
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormBuilder>

      <div
        className='position-absolute px-3 px-lg-5 bottom-30 bottom-sm-20'
        style={{ width: '100%', left: 0 }}
      >
        <div className='text-end mt-5 mt-lg-0 mt-sm-4 pe-2'>
          {formError && <small className='text-danger me-3'>Mandatory fields are not filled</small>}
          <button className='large_btn_apply rounded me-2' type='button' onClick={onClickSave}>
            Save & Next
          </button>
        </div>
      </div>

      <Modal
        show={canshowTimzonePopup}
        onHide={() => setCanshowTimzonePopup(false)}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='corner-near sx-close w-100'
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <div className='top_heading_styles'>Do you want to change the timezone?</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='top_para_styles'>
          <p className=''>
            Would you like to keep your existing availability slots or add new slots in the new time
            zone selected?
          </p>
          <p className='sx-text-primary'>
            Note: existing time slots will not be adjusted automatically to new time zone.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className='text-center pb-2'>
            <button
              className='large_btn_apply rounded me-3 btn-outline-primary'
              onClick={() => {
                goToAvailability(1);
              }}
            >
              Keep the existing slots
            </button>
            <button
              className='large_btn_apply rounded'
              onClick={() => {
                goToAvailability(2);
              }}
            >
              Add new slots
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
