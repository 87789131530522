import React from 'react';
import STAR_ICON from "../../assets/icon_images/star.svg";
import STAR_ICON_WITH_YELLOW from "../../assets/icon_images/star_yellow.svg";
import MultiRangeSlider from "multi-range-slider-react";

export const SliderComponent: React.FC<any> = ({ onSliderChange }) => {

  return (
    <>
      <div className='d-flex justify-content-between mx-1 mb-2'>
        <div className='side_heading'>Rate</div><div className='fs_14'>Range</div>
      </div>

      <MultiRangeSlider
        min={0}
        max={250}
        className="range-slider"
        thumbLeftColor="#F5BE17"
        thumbRightColor="#F5BE17"
        barInnerColor="#FFF"
        ruler={false}
        label={false}
        minValue={0}
        maxValue={250}
        onInput={(e) => {
          const obj: any = e;
          obj.type = "rate"
          onSliderChange(obj)
        }}
      />

      <div className='d-flex justify-content-between mx-1 mt-1'>
        <div className='fs_14'>Min (0)</div><div className='fs_14'>Max (250)</div>
      </div>

      <div className='d-flex justify-content-between mx-1 mb-2 mt-4'>
        <div className='side_heading'>Experience</div><div className='fs_14'>Range</div>
      </div>
      <MultiRangeSlider
        min={0}
        max={25}
        className="range-slider"
        thumbLeftColor="#F5BE17"
        thumbRightColor="#F5BE17"
        barInnerColor="#FFF"
        ruler={false}
        label={false}
        minValue={0}
        maxValue={25}
        onInput={(e) => {
          const obj: any = e;
          obj.type = "experience"
          onSliderChange(obj)
        }}
      />

      <div className='d-flex justify-content-between mx-1 mt-1'>
        <div className='fs_14'>Min (0)</div><div className='fs_14'>Max  (25)</div>
      </div>

      <div className='side_heading  mt-4'>Rating</div>
      <div className='mt-2'> <span>
        <img src={STAR_ICON_WITH_YELLOW} alt="" />
      </span>
        <span>
          <img src={STAR_ICON_WITH_YELLOW} alt="" />
        </span>
        <span>
          <img src={STAR_ICON_WITH_YELLOW} alt="" />
        </span>
        <span>
          <img src={STAR_ICON_WITH_YELLOW} alt="" />
        </span>
        <span>
          <img src={STAR_ICON} alt="" />
        </span></div>

    </>
  );

};


