// export const BASE_URL = 'https://shiftedx.mrdev.co.in';
// export const LINKEDIN_URL = 'https://d3hgl435bx7158.cloudfront.net/linkedin'; //'https://localhost:4000';
// export const CLOUDFRONT_URL = 'https://d3pt4q7bvbqslx.cloudfront.net';
// export const STORAGE_URL = 'https://shiftedx-data.s3.amazonaws.com/';
// const appConfig = {
//   BASE_URL: BASE_URL,
//   paymentKey: 'rzp_test_xg07ACCJYc3T4Y',
// };
// export default appConfig;
// export const zoomApiKey = 'FUDa-EbPRKurgG0KNE1BQQ';
// export const zoomApiSecret = 'xXbuOeMo94TUpOQbSgZXcco4r0GZNarJBddg';
// export const zoomSdkKey = 'nfK8EMXoXG8UhNVHMoqeEJIgiwDxaoYnaSqz';
// export const zoomSdkSecret = 'zoxx6Vin4aFfVDY23s2OEbOlUeRPoxYupbzT';
// export const linkedInClientId = '86tpzk0qh0g47h';
// export const linkedInClientSecret = 'J2IPOiitc5jwdBdP';
// export const googleSigningClientId =
//   '110337863357-a6m0pt8cogliaghr7rejui9capnl9a13.apps.googleusercontent.com';
// export const stripePK =
//   'pk_test_51JkKbSSHJXzrue2Jo8aGIimIkMSw6BxmIvYbFvmYgxsdN5OQ0vj6kl0fU5Tra8UJqR4KPdSTGPjJkXTK3lQPSVJR00bkGm8lyv';

// Production;
export const BASE_URL = 'https://api.siftedx.com';
export const LINKEDIN_URL = 'https://d3pt4q7bvbqslx.cloudfront.net/linkedin'; //'https://localhost:4000';
export const CLOUDFRONT_URL = 'https://d32hz9hs3mn2os.cloudfront.net';
export const STORAGE_URL = 'https://shiftedx-data.s3.amazonaws.com/';
const appConfig = {
  BASE_URL: BASE_URL,
  paymentKey: 'rzp_test_xg07ACCJYc3T4Y',
};
export default appConfig;
export const zoomApiKey = 'FUDa-EbPRKurgG0KNE1BQQ';
export const zoomApiSecret = 'xXbuOeMo94TUpOQbSgZXcco4r0GZNarJBddg';
export const zoomSdkKey = 'nfK8EMXoXG8UhNVHMoqeEJIgiwDxaoYnaSqz';
export const zoomSdkSecret = 'zoxx6Vin4aFfVDY23s2OEbOlUeRPoxYupbzT';
export const linkedInClientId = '86tpzk0qh0g47h';
export const linkedInClientSecret = 'J2IPOiitc5jwdBdP';
export const googleSigningClientId =
  '110337863357-a6m0pt8cogliaghr7rejui9capnl9a13.apps.googleusercontent.com';
export const stripePK =
  'pk_test_51MNbVdKSSN5GAJ3obLIQh9J9WFitbIbYUnqXBP2TO03RB05zkMdPtm4x6fcEvlfYG9vj3gZcxGRgF8QFtPzT87fR004i1emXBV';
