import React, { useState } from 'react'
import HAMBURGER_ICON from "../../assets/icon_images/hamburger_list_menu.svg";
import { CreateJobModal } from 'components/job/create-job-modal';



interface Props {
    title: string;
    subTitle: string;
    buttonName: string;
    editButtonClick: () => void;
    onInfoPopupClose: () => void;
    hideButton?: boolean;
}

const JobInfoPageHeader: React.FC<Props> = (props: Props) => {
    const [isShowCreateJobModal, setIsShowCreateJobModal] = useState(false);
    function onCloseModal() {
        setIsShowCreateJobModal(false)
        props.onInfoPopupClose();
    }
    return (
        <div className='row ps-3 pe-3 mt-4 mt-3 pe-lg-5 ms-3'>
            <div className='col-12 col-lg-12'>
                <h5 className='top_heading_styles'>{props.title}</h5>
            </div>
            <div className='col-8 col-lg-8 '>
                <p className='top_para_styles'>{props.subTitle}</p>
            </div>
            {
                !props.hideButton &&
                <div className='col-4 col-lg-4 text-end'>
                    <button className="large_btn_apply btn-outline-primary rounded me-4" onClick={() => props.editButtonClick()}>{props.buttonName}</button>
                    <button className="large_btn_apply rounded me-4" onClick={() => { setIsShowCreateJobModal(true) }}>
                        Edit
                    </button>
                    {/* <span><img src={HAMBURGER_ICON} alt="menu-icon" /></span> */}
                </div>
            }

            {isShowCreateJobModal && <CreateJobModal activesteps={0} onCloseModal={onCloseModal} />}
        </div>
    )
}

export default JobInfoPageHeader;